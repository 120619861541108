import { useState, useRef, useContext, useEffect } from "react";
import { IonPage, IonButton, IonIcon, IonModal } from "@ionic/react";
import { StatusHeader } from "../../ui/components/statusHeader";
import { TourContext, LexiconContext, ProgressContext } from "../../context";

import { arrowForward } from "ionicons/icons";
import { InfoModal } from "./info/infoModal";
import { HelpModal } from "./help/helpModal";
import { stationData, lexiconData } from "../../data/content";
import { BREAKPOINT } from "src/ui/variables";

export const LandingPage = ({
  setContentTab,
}: {
  setContentTab: (n: number) => void;
}) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);

  const { setStations } = useContext(TourContext);
  const { resetVisitedStations } = useContext(ProgressContext);
  const { setLexicon } = useContext(LexiconContext);
  useEffect(() => {
    setStations(stationData);
    setLexicon(lexiconData);
    setContentTab(0);
  }, []);

  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <IonPage>
      <IonModal
        ref={modal}
        isOpen={showHelpModal}
        initialBreakpoint={BREAKPOINT}
      >
        <HelpModal setShowModal={setShowHelpModal} />
      </IonModal>
      <IonModal
        ref={modal}
        isOpen={showInfoModal}
        initialBreakpoint={BREAKPOINT}
      >
        <InfoModal setShowModal={setShowInfoModal} />
      </IonModal>
      <StatusHeader
        titleText="Willkommen"
        buttonOnClick={() => setShowInfoModal(true)}
        buttonText="Info"
      />
      <div className="landingPage">
        <img
          style={{ maxHeight: "50vh", maxWidth: 300, marginBottom: 50 }}
          src="/assets/logo_white.png"
        />
        <IonButton
          color="darkfont"
          routerLink="contentselectionMap"
          style={{ marginBottom: 20, maxWidth: 300, minHeight: 30 }}
          onClick={() => resetVisitedStations()}
          className="LandingPageButton"
        >
          Starten
          <IonIcon
            slot="end"
            icon={arrowForward}
            style={{ paddingLeft: 10, minHeight: 30 }}
          />
        </IonButton>
        <IonButton
          color="darkfont"
          fill="outline"
          className="LandingPageButton"
          style={{ marginBottom: 20, minHeight: 30 }}
          onClick={() => setShowHelpModal(true)}
        >
          Anleitung
          {/* <IonIcon slot="start" icon={informationCircleOutline} /> */}
        </IonButton>
        <IonButton
          color="darkfont"
          fill="outline"
          className="LandingPageButton"
          style={{ marginBottom: 20, maxWidth: 300, minHeight: 30 }}
          onClick={() =>
            window.open("https://os0z7u597m1.typeform.com/to/svapJaaB")
          }
        >
          Gib uns Feedback
          {/* <IonIcon slot="start" icon={chatboxEllipsesOutline} /> */}
        </IonButton>
        <IonButton
          color="darkfont"
          fill="outline"
          className="LandingPageButton"
          style={{ marginBottom: 20 }}
          onClick={() =>
            window.open("https://os0z7u597m1.typeform.com/to/jmLK6SJB ")
          }
        >
          Baukultur einreichen
          {/* <IonIcon slot="start" icon={pencilOutline} /> */}
        </IonButton>
      </div>
    </IonPage>
  );
};
