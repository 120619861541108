import { importAll } from "../importFromPublic";

const cleanPaths = (paths: string[], withFileType: boolean) =>
  paths.map((entry: string) => {
    const parts = entry.split(".");
    const fileWithoutHash = withFileType ? `${parts[0]}.${parts[2]}` : parts[0];
    return fileWithoutHash.replace("/static/media/", "");
  });

export const lexiconParser = () => {
  const lemmaIds = cleanPaths(
    importAll(
      // @ts-ignore
      require.context("../../../public/assets/Lexikon/", false, /\.(md)$/)
    ),
    false
  );

  const lemmaImages = cleanPaths(
    importAll(
      // @ts-ignore
      require.context(
        "../../../public/assets/Lexikon/",
        false,
        /\.(png|jpe?g|svg)$/
      )
    ),
    true
  );

  return lemmaIds.map((lemmaID: string) => ({
    id: lemmaID,
    mdPath: lemmaID,
    images: lemmaImages.filter((img: string) => img.includes(lemmaID)),
  }));
};
