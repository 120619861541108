import L from "leaflet";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import { IStation } from "../../../data/model";


  /**  Types used for the map **/

export type Position = {
  timestamp?: number | null;
  coords: {
    accuracy?: number | null;
    altitude?: number | null;
    altitudeAccuracy?: number | null;
    heading?: number | null;
    latitude: number | null;
    longitude: number | null;
    speed?: number | null;
  };
};

export type bboxType = [[number, number], [number, number]];
export type centroidType = [number, number];
export type RouteType = {id: string; path: {lat: number, lng: number}[]; mTime: number; dTime: string; mDistance: number}

export const testAreaBern: [number, number][] = [
  [46.947, 7.453],
  [46.95, 7.456],
];

export const testAreaMuttenz: [number, number][] = [
  [47.53, 7.64],
  [47.54, 7.645],
];


  /**  Methods to calculate map parameters **/

  export const calcBbox = (stations: IStation[]): bboxType => {
    const lats = stations.map((station) => station.lat);
    const lons = stations.map((station) => station.lon);

    const latMin = Math.min(...lats);
    const latMax = Math.max(...lats);
    const lonMin = Math.min(...lons);
    const lonMax = Math.max(...lons);

    const bbox = [
      [latMin, lonMin],
      [latMax, lonMax],
    ];

    return bbox as bboxType;
  };

  export const calcCentroid = (stations: IStation[]): centroidType => {
    const latSum = stations.reduce((sum, val) => sum + val.lat, 0);
    const lonSum = stations.reduce((sum, val) => sum + val.lon, 0);

    const latlonTuple = [
      latSum / stations.length,
      lonSum / stations.length,
    ] || [0, 0];

    return latlonTuple as centroidType;
  };


  /**   Overrides leaflet`s default marker **/

const icon = "/assets/icon/transparent.png";

export let TransparentIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
  

  /**  Plug and play maps for leaflet **/

export const BASEMAPS: { [key: string]: { url: string; attribution: string } } = {
  osm: {
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  },
  swisstopo: {
    url: "https://wmts20.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg",
    attribution:
      '&copy; <a href=""https://www.geo.admin.ch/de/about-swiss-geoportal/impressum.html#copyright">swisstopo</a>',
  },
  swisstopo_grau: {
    url: "https://wmts20.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-grau/default/current/3857/{z}/{x}/{y}.jpeg",
    attribution:
      '&copy; <a href=""https://www.geo.admin.ch/de/about-swiss-geoportal/impressum.html#copyright">swisstopo</a>',
  },
};
