import { useContext } from "react";
import {
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
} from "@ionic/react";
import { Map } from "leaflet";

import { tourData, getStations } from "../../data/content";
import { ProgressContext, TourContext } from "../../context";
import { calcCentroid } from "../map/functionality/ressources";
import { ITour } from "../../data/model";
import { routes } from "../../data/content";
import { COLOR } from "../../ui/variables";
import { Canton } from "src/data/data/cantons";

const NoTourPlaceholder = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "60vh",
    }}
  >
    <p style={{ color: COLOR.intermediatefont }}>
      Keine Spaziergänge vorhanden
    </p>
  </div>
);

export const TourSelectionComponent = ({
  mapRef,
  canton,
}: {
  mapRef?: Map;
  canton: Canton;
}) => {
  const { setCurrentStations, setActiveTour } = useContext(TourContext);
  const { resetVisitedStations } = useContext(ProgressContext);

  const getRouteData = (tourId: string) =>
    routes.find((route) => route.id === tourId);

  const relevantTours = tourData.filter((tour) => tour.canton === canton.name);

  return (
    <IonContent>
      {relevantTours.length > 1 ? (
        relevantTours.map((tour: ITour) => (
          <IonCard
            key={tour.id}
            onClick={() => {
              const stations = getStations(tour.id);
              if (!stations) {
                return;
              }
              setActiveTour(tour); // Set tour context
              setCurrentStations(stations); // Set station context
              resetVisitedStations(); // Reset visisted stations to none

              mapRef?.setView(calcCentroid(stations), 13);
            }}
            routerLink={`/${tour.id}/intro`}
          >
            <IonCardHeader style={{ textAlign: "center", marginTop: -20 }}>
              <h5 style={{ color: COLOR.protodark }}>{tour.title}</h5>
            </IonCardHeader>
            <IonCardContent>
              <img
                src={`/assets/Datenbank/${tour.id}.jpg`}
                alt="test"
                style={{
                  cursor: "pointer",
                  marginTop: -20,
                  marginBottom: -20,
                }}
              />
            </IonCardContent>
            <p
              style={{
                margin: "2px 8px 10px 8px",
                color: COLOR.darkfont,
                textAlign: "center",
              }}
            >{`Länge: ${getRouteData(tour.id)?.mDistance} km, Zeit unterwegs: ${
              getRouteData(tour.id)?.dTime
            }`}</p>
          </IonCard>
        ))
      ) : (
        <NoTourPlaceholder />
      )}
    </IonContent>
  );
};
