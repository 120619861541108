import { IonCard, IonIcon } from "@ionic/react";
import { banOutline } from "ionicons/icons";
import { IStation } from "src/data/model";
import { COLOR } from "./variables";

type Props = {
  children: React.ReactElement | React.ReactElement[];
  style?: any;
};

export const UIPlaceholder = () => (
  <div style={{ width: 90, height: 40 }}></div>
);

export const FhnwLogo = () => (
  <img
    alt="fhnw_log"
    height="20"
    src="/assets/fhnw_logo_small.PNG"
    style={{ marginRight: "8px" }}
  />
);

export const NoAvailPlaceholder = () => (
  <div
    style={{
      width: 90,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 14,
    }}
  >
    <IonIcon slot="start" icon={banOutline} color="intermediatefont" />
  </div>
);

export const CustomIonCard = ({ children }: Props) => (
  <IonCard
    style={{
      boxShadow: "none",
      margin: "0 0 80px 0",
    }}
  >
    {children}
  </IonCard>
);

export const StationAddress = ({ station }: { station: IStation }) => (
  <>
    <h3>{station.station_name}</h3>
    <h6
      style={{
        marginTop: -10,
        color: COLOR.intermediatefont,
      }}
    >
      {station.adress}
    </h6>
  </>
);
