import { useContext, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { IonContent, IonGrid, IonPage, IonModal } from "@ionic/react";
import axios from "axios";
import { Map } from "leaflet";

import { ContentComponent } from "./content/contentComponent";
import { TabBar } from "../../ui/components/tabBar";
import { ModalContent } from "../../ui/components/modalContent";
import { TourDefaultHeader } from "../../ui/components/statusHeader";
import { ActionFooter } from "../../ui/components/actionFooter";
import { LexiconContext, ProgressContext, TourContext } from "../../context";
import { QuizModal } from "./quiz/quizModal";
import { LexiconModal } from "./lexicon/lexiconModal";
import {
  quizData_Diff,
  quizData_Mult,
  quizData_Slider,
} from "../../data/content";
import { IQuiz_Mult, IQuiz_Diff, IQuiz_Slider } from "../../data/model";
import { BREAKPOINT } from "src/ui/variables";
import { StationFooter } from "./uiElements/stationFooter";
import { AudioFooter } from "./uiElements/audioFooter";

const StationComponent = ({
  mapRef,
  noTour,
}: {
  mapRef?: Map;
  noTour: boolean;
}) => {
  const { id, station_id } = useParams<{ id: string; station_id: string }>();

  const { visitedStations } = useContext(ProgressContext);
  const stations = useContext(TourContext).getStations();
  const { activeEntryId } = useContext(LexiconContext);

  const [activeTab, setActiveTab] = useState(0); // Update tab ui for both button- and swipe-event
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [showLexiconModal, setShowLexiconModal] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);

  const [mdPathOverview, setMdPathOverview] = useState<string>("");
  const [mdPathDetail, setMdPathDetail] = useState<string>("");
  const [mdPathLexicon, setMdPathLexicon] = useState<string>("");
  const [audioFile, setAudioFile] = useState<string>("");
  const [quizCollection, setQuizCollection] = useState<
    (IQuiz_Diff | IQuiz_Mult | IQuiz_Slider)[]
  >([]);

  const quizModalRef = useRef<HTMLIonModalElement>(null);
  const lexiconModalRef = useRef<HTMLIonModalElement>(null);

  const potentialMdFileOverview = `/assets/Datenbank/${station_id}_OVERVIEW_DE.md`;
  const potentialMdFileDetail = `/assets/Datenbank/${station_id}_Detail_DE.md`;
  const potentialMdFileLexicon = `/assets/Lexikon/${activeEntryId}.md`;
  const potentialAudioPath = `/assets/Audio/${station_id}_Overview_Audio_DE.mp3`;

  useEffect(() => {
    // Close map popup as it is still open:
    mapRef && mapRef.closePopup();

    // Load .md files
    axios
      .get(potentialMdFileOverview)
      .then((response) => {
        response.status === 200
          ? setMdPathOverview(potentialMdFileOverview)
          : setMdPathOverview("");
      })
      .catch((error) => {
        setMdPathOverview("");
      });
    axios
      .get(potentialMdFileDetail)
      .then((response) => {
        response.status === 200 && setMdPathDetail(potentialMdFileDetail);
      })
      .catch((error) => {
        setMdPathDetail("");
      });
  }, [station_id]);

  useEffect(() => {
    axios
      .get(potentialMdFileLexicon)
      .then((response) => {
        response.status === 200 && setMdPathLexicon(potentialMdFileLexicon);
      })
      .catch((error) => {
        setMdPathLexicon("");
      });
  }, [activeEntryId]);
  useEffect(() => {
    axios
      .get(potentialAudioPath)
      .then((response) => {
        setAudioFile(potentialAudioPath);
      })
      .catch((error) => {
        setAudioFile("");
      });
  }, [station_id]);

  useEffect(() => {
    const stationFilter = (quiz: IQuiz_Diff | IQuiz_Mult | IQuiz_Slider) => {
      return quiz && quiz.quiz_id ? quiz.quiz_id.includes(station_id) : false;
    };

    const relevantQuizzes = [
      quizData_Slider.filter(stationFilter),
      quizData_Diff.filter(stationFilter),
      quizData_Mult.filter(stationFilter),
    ]
      .flat()
      .sort(
        (quizA, quizB) =>
          parseInt(quizA.quiz_id.slice(-2)) - parseInt(quizB.quiz_id.slice(-2))
      );

    setQuizCollection(relevantQuizzes || []);
  }, [station_id]);

  const tabDefinition = ["Übersicht", "Detail"];

  const thisStation = stations.find(
    (station) => station_id === station.station_id
  );

  if (!thisStation) {
    return (
      <IonPage>
        <TourDefaultHeader />
        <div>Error finding station</div>;
      </IonPage>
    );
  }

  return (
    <IonPage>
      {quizCollection.length > 0 && (
        <IonModal
          ref={quizModalRef}
          isOpen={showQuizModal}
          initialBreakpoint={BREAKPOINT}
        >
          <ModalContent setShowModal={setShowQuizModal} title="Quiz" closable>
            <QuizModal
              station={thisStation}
              setShowModal={setShowQuizModal}
              quizCollection={quizCollection}
            />
          </ModalContent>
        </IonModal>
      )}
      <IonModal
        ref={lexiconModalRef}
        isOpen={showLexiconModal}
        initialBreakpoint={BREAKPOINT}
        onIonModalDidDismiss={() => setShowLexiconModal(false)}
      >
        <ModalContent
          setShowModal={setShowLexiconModal}
          title="Lexikon"
          closable
        >
          <LexiconModal
            mdPathLexicon={mdPathLexicon}
            entityId={activeEntryId}
            entityName={activeEntryId}
          />
        </ModalContent>
      </IonModal>
      <TourDefaultHeader />
      <IonGrid fixed={true}>
        <TabBar
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabDefinition={tabDefinition}
          setAudioPlaying={setAudioPlaying}
        />
        <IonContent>
          <ContentComponent
            activeTab={activeTab}
            mdPathOverview={mdPathOverview}
            mdPathDetail={mdPathDetail}
            stationData={thisStation}
            setShowLexiconModal={setShowLexiconModal}
            audioPlaying={audioPlaying}
            setAudioPlaying={setAudioPlaying}
            hasAudioFile={audioFile !== "" }
          />
        </IonContent>
      </IonGrid>

      <ActionFooter>
        {audioPlaying  ? (
          <AudioFooter
            audioFile={audioFile}
            setAudioPlaying={setAudioPlaying}
          />
        ) : (
          <StationFooter
            quizCollectionLength={quizCollection.length}
            stationLength={stations.length}
            visitedStationsLength={visitedStations.length}
            {...{
              noTour,
              setShowQuizModal,
              setActiveTab,
              id,
            }}
          />
        )}
      </ActionFooter>
    </IonPage>
  );
};

export default StationComponent;
