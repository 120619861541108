import React from "react";
import { IStation, ITour } from "./data/model";

const tourDefaultState = {
  id: "Tour Default",
  title: "Tour Default",
  input: "Tour Default",
  city: "Tour Default",
  canton: "Tour Default",
};

export type TourContextType = {
  stations: IStation[];
  setStations: (station: IStation[]) => void;
  getStations: () => IStation[];
  currentStations: IStation[];
  setCurrentStations: (station: IStation[]) => void;
  getCurrentStations: () => IStation[];
  tours: ITour[];
  setTours: (tour: ITour[]) => void;
  getTours: () => ITour[];
  activeTour: ITour;
  setActiveTour: (tour: ITour) => void;
  getActiveTour: () => ITour;
};

type Props = { children: React.ReactElement | React.ReactElement[] };

export const TourContext = React.createContext<TourContextType>({
  stations: [],
  setStations: () => {},
  getStations: () => [],
  currentStations: [],
  setCurrentStations: () => {},
  getCurrentStations: () => [],
  tours: [],
  setTours: () => {},
  getTours: () => [],
  activeTour: tourDefaultState,
  setActiveTour: () => {},
  getActiveTour: () => tourDefaultState,
});

export const TourProvider = ({ children }: Props) => {
  const [stations, setStationState] = React.useState<IStation[]>([]); // Complete list of stations
  const [currentStations, setCurrentStationsState] = React.useState<IStation[]>( // Stations currently selected through active tour
    []
  );
  const [tours, setTourState] = React.useState<ITour[]>([]); // Complete list of tours
  const [activeTour, setActiveTourState] =
    React.useState<ITour>(tourDefaultState); // Currently active tour

  const setStations = (stations: IStation[]) => {
    localStorage.setItem("stations", JSON.stringify(stations));
    setStationState(stations);
  };

  const getStations = () =>
    stations.length
      ? stations
      : JSON.parse(localStorage.getItem("stations") || "");

  const setCurrentStations = (currentStations: IStation[]) => {
    localStorage.setItem("currentStations", JSON.stringify(currentStations));
    setCurrentStationsState(currentStations);
  };

  const getCurrentStations = () =>
    currentStations.length
      ? currentStations
      : JSON.parse(localStorage.getItem("currentStations") || "");

  const setTours = (tours: ITour[]) => {
    localStorage.setItem("tours", JSON.stringify(tours));
    setTourState(tours);
  };

  const getTours = () =>
    tours.length ? tours : JSON.parse(localStorage.getItem("tours") || "");

  const setActiveTour = (activeTour: ITour) => {
    localStorage.setItem("activeTour", JSON.stringify(activeTour));
    setActiveTourState(activeTour);
  };

  const getActiveTour = () =>
    activeTour.id
      ? activeTour
      : JSON.parse(localStorage.getItem("activeTour") || "");

  return (
    <TourContext.Provider
      value={{
        stations,
        setStations,
        getStations,
        currentStations,
        setCurrentStations,
        getCurrentStations,
        tours,
        setTours,
        getTours,
        activeTour,
        setActiveTour,
        getActiveTour,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

export type visitedStationsType = { id: string; index: number };

export type ProgressContextType = {
  visitedStations: visitedStationsType[];
  markStationVisited: (station: visitedStationsType) => void;
  resetVisitedStations: () => void;
};

export const ProgressContext = React.createContext<ProgressContextType>({
  visitedStations: [],
  markStationVisited: () => {},
  resetVisitedStations: () => {},
});

export const VisitedStationsProvider = ({ children }: Props) => {
  const [visitedStations, setStationVisited] = React.useState<
    visitedStationsType[]
  >([]);
  const markStationVisited = (newStation: visitedStationsType) => {
    const stationInList = visitedStations.find(
      (station) => station.id === newStation.id
    );
    !stationInList
      ? setStationVisited((stations) => [...stations, newStation])
      : null;
  };
  const resetVisitedStations = () => setStationVisited([]);

  return (
    <ProgressContext.Provider
      value={{ visitedStations, markStationVisited, resetVisitedStations }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export type Lexicon = {
  id: string;
  mdPath: string;
  images: string[];
};

export type LexiconContextType = {
  setLexicon: (lexicon: Lexicon[]) => void;
  getLexicon: () => Lexicon[];
  findInLexicon: (predicate: string) => Lexicon | undefined;
  setActiveEntryId: (lexiconId: string) => void;
  activeEntryId: string;
};

export const LexiconContext = React.createContext<LexiconContextType>({
  getLexicon: () => [],
  setLexicon: () => {},
  setActiveEntryId: () => {},
  findInLexicon: () => ({ id: "", mdPath: "", images: [] }),
  activeEntryId: "defaultEntry",
});

export const LexiconContextProvider = ({ children }: Props) => {
  const [lexiconState, setLexiconState] = React.useState<Lexicon[]>([]);
  const [activeEntryId, setActiveEntry] = React.useState<string>("");

  const setActiveEntryId = (e: string) => {
    setActiveEntry(e);
  };

  const setLexicon = (lexicon: Lexicon[]) => {
    localStorage.setItem("lexicon", JSON.stringify(lexicon));
    setLexiconState(lexicon);
  };

  const getLexicon = () =>
    lexiconState.length > 1
      ? lexiconState
      : JSON.parse(localStorage.getItem("lexicon") || "");

  const findInLexicon = (predicate: string) => {
    if (lexiconState.length < 1) {
      return;
    }
    return lexiconState.find((entry) => entry.id === predicate);
  };

  return (
    <LexiconContext.Provider
      value={{
        getLexicon,
        setLexicon,
        findInLexicon,
        activeEntryId,
        setActiveEntryId,
      }}
    >
      {children}
    </LexiconContext.Provider>
  );
};
