import { IonProgressBar, IonRow } from "@ionic/react";
import { COLOR } from "../../../ui/variables";
import { ProgressHeader } from "../../../ui/titles";

export const Progressbar = ({
  visitedStationsCount,
  stationCount,
  tourTitle,
  onClick,
}: {
  visitedStationsCount: number;
  stationCount: number;
  tourTitle: string;
  onClick: () => void;
}) => {
  const roundToDecimal = (num: number) =>
    Math.round((num + Number.EPSILON) * 100) / 100;

  return (
    <IonRow
      onClick={onClick}
      style={{
        padding: 6,
        width: "100%",
        textAlign: "center",
        borderBottom: `1px solid ${COLOR.intermediatefont}`,
      }}
    >
      <div
        style={{ flexDirection: "column", width: "100%", padding: "0px 2px" }}
      >
        <ProgressHeader
          text={tourTitle}
          style={{ color: "black", paddingTop: 4 }}
        />
        <IonProgressBar
          title="12"
          color="protored"
          value={roundToDecimal(visitedStationsCount / stationCount)}
          style={{ marginTop: 5, marginBottom: 5, height: 4 }}
        >
          123
        </IonProgressBar>
      </div>
    </IonRow>
  );
};
