import { useContext, useEffect, useState, useRef } from "react";
import {
  IonContent,
  IonLabel,
  IonPage,
  IonText,
  IonNote,
  IonList,
  IonItem,
  IonModal,
} from "@ionic/react";
import { TourContext } from "../../context";
import { ActionFooter } from "../../ui/components/actionFooter";
import { StatusHeader } from "../../ui/components/statusHeader";
import { ModalContent } from "../../ui/components/modalContent";
import { FilledButton, OutlinedButton } from "../../ui/buttons";
import { BREAKPOINT, COLOR } from "../../ui/variables";
import { HelpModal } from "../landingPage/help/helpModal";
import { IStation, ITour } from "src/data/model";

export const StationList = ({
  stations,
  tour,
}: {
  stations: IStation[];
  tour: ITour;
}) => (
  <IonContent style={{ maxHeight: "80vh" }}>
    <div
      style={{
        padding: "20px 20px 20px 20px",
        marginBottom: 10,
        borderBottom: `1px solid ${COLOR.systemGrey}`,
      }}
    >
      <div style={{ textAlign: "center" }}>
        <IonText
          style={{
            size: "large",
          }}
        >
          {tour.title}
        </IonText>
        <p
          style={{
            color: COLOR.protored,
            fontSize: "0.80em",
            wordBreak: "break-word",
            textAlign: "left",
            padding: "0 4px",
            marginTop: 6,
          }}
        >
          {tour.input || ""}
        </p>
      </div>
    </div>
    <div style={{ marginLeft: 20, paddingTop: 2 }}>Stationen:</div>
    <IonList>
      {stations.map((station, index) => (
        <IonItem key={index}>
          <IonNote slot="start" style={{ fontSize: 15 }}>
            {index + 1}.
          </IonNote>
          <IonLabel>
            <IonText>{station.station_name}</IonText>
            <p>{station.adress}</p>
          </IonLabel>
        </IonItem>
      ))}
    </IonList>
  </IonContent>
);

export const IntroductionComponent = () => {
  const tour = useContext(TourContext).getActiveTour();
  const stations = useContext(TourContext).getCurrentStations();

  const [showModal, setShowModal] = useState(false);
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <IonPage>
      <IonModal ref={modal} isOpen={showModal} initialBreakpoint={BREAKPOINT}>
        <ModalContent setShowModal={setShowModal} title="Anleitung" closable>
          <HelpModal setShowModal={setShowModal} />
        </ModalContent>
      </IonModal>
      <StatusHeader
        routerLink="/landing"
        buttonText="Abbrechen"
        titleText="Stationen"
      />

      <StationList stations={stations} tour={tour} />
      <ActionFooter>
        <OutlinedButton
          text="Zurück"
          routerLink="/contentselectionTour"
          routerDirection="back"
        />
        <FilledButton
          text="Los geht's"
          color="darkfont"
          routerLink={`/${tour.id}/map`}
        />
      </ActionFooter>
    </IonPage>
  );
};
