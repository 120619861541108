import { IonPage } from "@ionic/react";
import { StatusHeader } from "src/ui/components/statusHeader";
import { TabBarContent } from "../../../ui/components/tabBar";
import { ActionFooter } from "../../../ui/components/actionFooter";
import { Map } from "leaflet";
import { QuickSelect } from "./quickSelect";
import { Canton } from "src/data/data/cantons";

type ContentWrapperProps = {
  contentMapRef?: Map;
  contentTab: number;
  setContentTab: (n: number) => void;
  children: React.ReactElement;
  canton: Canton;
  setCanton: (canton: Canton) => void;
  quickSelectOpen: boolean;
  setQuickSelect: (state: boolean) => void;
};

export const ContentWrapper = ({
  contentTab,
  setContentTab,
  contentMapRef,
  children,
  canton,
  setCanton,
  quickSelectOpen,
  setQuickSelect,
}: ContentWrapperProps) => (
  <IonPage>
    <StatusHeader
      titleText="Auswahl"
      buttonText="Zurück"
      routerLink="landing"
    />
    <TabBarContent activeTab={contentTab} setActiveTab={setContentTab} />
    {children}

    <ActionFooter>
      <QuickSelect
        mapRef={contentMapRef}
        canton={canton}
        setCanton={setCanton}
        quickSelectOpen={quickSelectOpen}
        setQuickSelect={setQuickSelect}
      />
    </ActionFooter>
  </IonPage>
);
