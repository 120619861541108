import {
  IonButton,
  IonIcon,
  IonButtons,
  IonContent,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { BOXSHADOW, COLOR } from "../variables";
import { closeOutline } from "ionicons/icons";
export const ModalContent = ({
  setShowModal,
  children,
  title,
  closable,
}: {
  setShowModal: (showModal: boolean) => void;
  children: React.ReactNode;
  title: string;
  closable: boolean;
}) => {
  return (
    <>
      <IonToolbar
        style={{
          boxShadow: BOXSHADOW,
        }}
      >
        <IonTitle
          style={{
            textAlign: "center",
            color: COLOR.protodark,
            marginRight: closable ? -50 : -1,
            fontWeight: 100,
          }}
        >
          {title}
        </IonTitle>
        <IonButtons slot="end">
          {closable && (
            <IonButton onClick={() => setShowModal(false)}>
              <IonIcon slot="icon-only" icon={closeOutline} color="protored" />
            </IonButton>
          )}
        </IonButtons>
      </IonToolbar>
      {children}
    </>
  );
};
