import React, { useEffect } from "react";
import {
  IonApp,
  setupIonicReact,
  IonRouterOutlet,
  useIonToast,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { Map } from "leaflet";

import MapComponent from "./components/map/mapComponent";
import StationComponent from "./components/station/stationComponent";
import { IntroductionComponent } from "./components/introduction/introductionComponent";
import { FinishComponent } from "./components/finish/finishComponent";
import { LoadingComponent } from "./components/loading/loadingComponent";
import { LandingPage } from "./components/landingPage/landingPageComponent";
import {
  ContentSelectionMap,
  ContentSelectionTour,
} from "./components/contentSelection/contentSelection";
import LocationService from "./components/map/functionality/geolocationPermissions";
import {
  LexiconContext,
  LexiconContextProvider,
  TourProvider,
  VisitedStationsProvider,
} from "./context";
import { CANTONS, Canton } from "./data/data/cantons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./ui/styles.css";
setupIonicReact();

const App = () => {
  const [mapRef, setMapRef] = React.useState<Map>();
  const [contentMapRef, setContentMapRef] = React.useState<Map>();
  const [canton, setCanton] = React.useState<Canton>(CANTONS[2]);
  const [contentTab, setContentTab] = React.useState(0);
  const [quickSelectOpen, setQuickSelect] = React.useState(false);
  const [present, dismiss] = useIonToast();

  const postGPSPermission = async (canUseGPS: boolean) => {
    return;
    // Disabled until iOS permission request can be implemented

    // canUseGPS
    //   ? null
    //   : await present({
    //       position: "middle",
    //       buttons: [{ text: "Schliessen", handler: () => dismiss() }],
    //       message: "Bitte GPS aktivieren, um die Navigation zu ermöglichen",
    //       color: "dark",
    //     });
  };

  const checkPermissions = async () => {
    const hasPermission = await LocationService.checkGPSPermission();
    if (hasPermission) {
      if (Capacitor.isNativePlatform()) {
        const canUseGPS = await LocationService.askToTurnOnGPS();
        postGPSPermission(canUseGPS);
      } else {
        const permission = await LocationService.requestGPSPermission();
        if (permission === "CAN_REQUEST" || permission === "GOT_PERMISSION") {
          if (Capacitor.isNativePlatform()) {
            const canUseGPS = await LocationService.askToTurnOnGPS();
            postGPSPermission(canUseGPS);
          } else {
            postGPSPermission(true);
          }
        } else {
          await present({
            position: "middle",
            message: "Keine GPS Berechtigung, Standort wird nicht angezeigt",
            buttons: [{ text: "Schliessen", handler: () => dismiss() }],
            color: "dark",
          });
        }
      }
    }
  };
  useEffect(() => {
    checkPermissions();
  }, [contentTab]);

  return (
    <TourProvider>
      <VisitedStationsProvider>
        <LexiconContextProvider>
          <IonApp>
            <IonReactRouter>
              <IonRouterOutlet>
                <Route path="/loading">
                  <LoadingComponent />
                </Route>
                <Route path="/landing">
                  <LandingPage setContentTab={setContentTab} />
                </Route>
                <Route path="/contentselectionMap">
                  <ContentSelectionMap
                    {...{
                      setContentTab,
                      setContentMapRef,
                      contentMapRef,
                      canton,
                      setCanton,
                      quickSelectOpen,
                      setQuickSelect,
                    }}
                  />
                </Route>
                <Route path="/contentselectionTour">
                  <ContentSelectionTour
                    {...{
                      setContentTab,
                      setContentMapRef,
                      contentMapRef,
                      canton,
                      setCanton,
                      quickSelectOpen,
                      setQuickSelect,
                    }}
                  />
                </Route>
                <Route path="/:id/intro" component={IntroductionComponent}>
                  <IntroductionComponent />
                </Route>
                <Route path="/:id/map">
                  <MapComponent setMapReference={setMapRef} mapRef={mapRef} />
                </Route>
                {/***  Route for stations on a tour ***/}
                <Route path="/:id/station/:station_id">
                  <StationComponent mapRef={mapRef} noTour={false} />
                </Route>
                {/***  Route for singular stations ***/}
                <Route path="/station/:station_id">
                  <StationComponent mapRef={mapRef} noTour />
                </Route>
                <Route path="/:id/end" component={FinishComponent} />
                <Redirect exact from="/" to="/loading" />
              </IonRouterOutlet>
            </IonReactRouter>
          </IonApp>
        </LexiconContextProvider>
      </VisitedStationsProvider>
    </TourProvider>
  );
};

export default App;
