import { useState, useRef } from "react";
import { IonButton } from "@ionic/react";
import { IonContent, IonPopover } from "@ionic/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import "swiper/css";

import {
  IQuiz_Mult,
  IQuiz_Diff,
  IQuiz_Slider,
  IStation,
} from "../../../data/model";

import { LogicWrapperSlider, QuizSlider } from "./withSlider";
import {
  QuizMultChoice,
  QuizDifference,
  LogicWrapperCheckboxes,
} from "./withCheckboxes";

export const QuizModal = ({
  station,
  setShowModal,
  quizCollection,
}: {
  station: IStation;
  setShowModal: (showModal: boolean) => void;
  quizCollection: (IQuiz_Diff | IQuiz_Mult | IQuiz_Slider)[];
}) => {
  const [quizSwiper, referenceSwiper] = useState<SwiperType>({} as SwiperType); // Swiper component reference

  const [activeQuizIndex, setActiveQuizIndex] = useState<number>(0);
  const [answer, setAnswer] = useState<boolean>(false);
  const [readyToClose, setReadyToClose] = useState<boolean>(false); // For mandatory quiz only
  const popover = useRef<HTMLIonPopoverElement>(null);

  const checkAnswer = (swiper: SwiperType) => {
    if (answer) {
      setTimeout(() => {
        if (activeQuizIndex + 1 === quizCollection.length) {
          popover.current?.dismiss();
          setShowModal(false);
        } else {
          popover.current?.dismiss();
          setActiveQuizIndex(activeQuizIndex + 1);
          swiper.slideNext();
        }
      }, 1000);
    } else {
      setTimeout(() => {
        popover.current?.dismiss();
      }, 500);
      return false;
    }
  };

  const loadQuizComponent = (
    activeQuiz: IQuiz_Diff | IQuiz_Mult | IQuiz_Slider
  ) => {
    if (activeQuiz.hasOwnProperty("slidersolution")) {
      return (
        <LogicWrapperSlider
          quiz={activeQuiz as IQuiz_Slider}
          setAnswer={setAnswer}
          render={(changeSlider) => (
            <QuizSlider
              changeSlider={changeSlider}
              quiz={activeQuiz as IQuiz_Slider}
            />
          )}
        />
      );
    }
    if (
      activeQuiz.hasOwnProperty("picture1") ||
      activeQuiz.hasOwnProperty("picture2")
    ) {
      return (
        <LogicWrapperCheckboxes
          quiz={activeQuiz as IQuiz_Diff}
          setAnswer={setAnswer}
          render={(changeSelection) => (
            <QuizDifference
              changeSelection={changeSelection}
              quiz={activeQuiz as IQuiz_Diff}
            />
          )}
        />
      );
    } else {
      return (
        <LogicWrapperCheckboxes
          quiz={activeQuiz as IQuiz_Mult}
          setAnswer={setAnswer}
          render={(changeSelection) => (
            <QuizMultChoice
              quiz={activeQuiz as IQuiz_Mult}
              changeSelection={changeSelection}
            />
          )}
        />
      );
    }
  };

  return (
    <IonContent style={{ textAlign: "center", maxHeight: "80vh" }}>
      <div className="quizWrapper">
        <div style={{ flexGrow: 0.8 }}>
          <Swiper
            allowTouchMove={false}
            onInit={(thisSwiper) => {
              referenceSwiper(thisSwiper);
            }}
          >
            {quizCollection.map((quizItem, index) => (
              <SwiperSlide key={index}>
                {loadQuizComponent(quizItem)}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="quizAnswerButtonWrapper">
          <IonButton
            hidden={readyToClose}
            id="popupTrigger"
            color="darkfont"
            fill="solid"
            onClick={() => checkAnswer(quizSwiper)}
          >
            Antworten
          </IonButton>
          <IonPopover
            keyboardClose
            arrow={false}
            trigger="popupTrigger"
            ref={popover}
          >
            {answer ? (
              <IonContent className="ion-padding" id="popoverCorrectAnswer">
                Das ist richtig!
              </IonContent>
            ) : (
              <IonContent className="ion-padding" id="popoverIncorrectAnswer">
                Leider falsch
              </IonContent>
            )}
          </IonPopover>
          {readyToClose && (
            <IonButton
              color="darkfont"
              fill="outline"
              onClick={() => setShowModal(false)}
            >
              Schliessen
            </IonButton>
          )}
        </div>
      </div>
    </IonContent>
  );
};
