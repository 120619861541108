
/** Colors **/

export enum COLOR {
  white = "FFFFFF",
  black = "000000",

  protored = "#A31111",
  protoredShade = "#8f0f0f",
  protoredTint = "#ac2929",

  protodark = "#454545",
  protodarkShade = "#3d3d3d",
  protodarkTint = "#585858",

  darkfont = "#393939",
  darkfontShade = "#323232",
  darkfontTint = "#4d4d4d",

  intermediatefont = "#9B9B9B",
  intermediatefontShade = "#888888",
  intermediatefontTint = "#a5a5a5",

  lightfont = "#EAEAEA",
  lightfontShade = "#cecece",
  lightfontTint = "#f4f4f4",

  systemGrey = "#c8c7cc",

  positionDot = "#1967d2",
  routeDot = "#414141",
  routeDotOutline = "#000000",
  routeNextStation = "#96241c",
  routeUnvisitedStation = "#df736b",
  routeVisitedStation = "#A2A4A6"
}


/** Heights **/
export enum HEIGHT {
  statusHeader = 45,
  actionFooter = 60,
  tabBar = 30,
}

/** Break point for modals */
export const BREAKPOINT = 0.9

export const BOXSHADOW = "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
