import { ModalContent } from "src/ui/components/modalContent";
import { IonContent } from "@ionic/react";
import { COLOR } from "../../../ui/variables";
import "../../../ui/styles.css";

export const InfoModal = ({
  setShowModal,
}: {
  setShowModal: (showModal: boolean) => void;
}) => (
  <ModalContent setShowModal={setShowModal} title="Impressum" closable>
    <IonContent
      style={{
        maxHeight: "80vh",
      }}
    >
      <div
        style={{
          textAlign: "center",
          color: COLOR.intermediatefont,
          padding: "0 10px",
          marginBottom: 40,
        }}
      >
        <img
          style={{ maxHeight: 200, maxWidth: 200, margin: "20px 0px 0px 0px" }}
          src="/assets/logo_white.png"
        />
        <h6 style={{ padding: 12, margin: "10px 0px", fontStyle: "italic" }}>
          Baukultur Schweiz ist ein Forschungsprojekt der Hochschule für
          Architektur, Bau und Geomatik an der FHNW.
        </h6>

        <div style={{ padding: 12, textAlign: "left" }}>
          <p className="impressumHeading">Kooperationspartner:</p>
          Baukulturen der Schweiz <br />
          Inhaltliche Kuratierung der Plattform
          <p className="impressumHeading">Idee und Projektleitung:</p>
          Susanne Bleisch und Harald R. Stühlinger
          <p className="impressumHeading">
            Konzeption, Design und Inhaltliche Aufarbeitung:
          </p>
          Henning Weiss
          <p className="impressumHeading">Technische Umsetzung:</p>
          Friedrich Striewski
          <p className="impressumHeading">Inhaltliche Kooperationspartner:</p>
          <ul>
            <li>Institut Architektur FHNW</li>
            <li>IG Hans Loepfe</li>
            <li>Baukulturen der Schweiz 1945 - 1975</li>
            <li>Auszeichnung Gutes Bauen 2023 Basel-Landschaft Basel-Stadt</li>
          </ul>
        </div>

        <h6 style={{ padding: 12, margin: "5px 0px" }}>
          Wir freuen uns über Ihr Feedback! Hier können Sie die App bewerten:
        </h6>
        <div style={{ margin: 10 }}>
          <a href="https://os0z7u597m1.typeform.com/to/svapJaaB">
            Feedback Form
          </a>
        </div>
      </div>
    </IonContent>
  </ModalContent>
);
