import { useState } from "react";
import { IonIcon, IonItem, IonRange } from "@ionic/react";
import { addOutline, removeOutline } from "ionicons/icons";

import { IQuiz_Slider } from "../../../data/model";
import { QuizQuestion } from "../../../ui/titles";
import { COLOR } from "src/ui/variables";
import { imagePaths } from "src/data/importFromPublic";

export const LogicWrapperSlider = (props: {
  quiz: IQuiz_Slider;
  setAnswer: (answer: boolean) => void;
  render: (changeSlider: (val: number) => void) => React.ReactChild;
}) => {
  const { quiz, setAnswer } = props;

  const changeSlider = (val: number) => {
    setAnswer(quiz.slidersolution === val);
  };

  return <>{props.render(changeSlider)}</>;
};

export const QuizSlider = ({
  changeSlider,
  quiz,
}: {
  quiz: IQuiz_Slider;
  changeSlider: (value: number) => void;
}) => {
  const [sliderState, setSliderState] = useState<number>(0);
  const quizImage = imagePaths.find((path: string) =>
    path.includes(quiz.picture1)
  );
  return (
    <div style={{ padding: "4px 0" }}>
      <QuizQuestion text={quiz.question} />
      <hr />

      {quizImage ? (
        <img src={quizImage} alt="Quiz Img 1" className="quizImg" />
      ) : null}
      <IonItem className="quizItem" lines="none">
        <p
          style={{
            // color: "grey",
            width: "100%",
            textAlign: "center",
          }}
        >
          Antwort: {sliderState} m
        </p>
      </IonItem>
      <IonRange
        color="protored"
        min={quiz.sliderstart}
        max={quiz.sliderend}
        step={quiz.slidertick}
        pin
        snaps
        ticks
        onIonChange={(e) => {
          setSliderState(e.detail.value as number);
          changeSlider(e.detail.value as number);
        }}
      >
        <IonIcon
          size="small"
          slot="start"
          icon={removeOutline}
          style={{ color: COLOR.intermediatefont, padding: 8 }}
        />
        <IonIcon
          slot="end"
          icon={addOutline}
          style={{ color: COLOR.intermediatefont, padding: 8 }}
        />
      </IonRange>
    </div>
  );
};
