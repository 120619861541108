import { useEffect } from "react";
import { useMap, useMapEvents } from "react-leaflet";
import Locate from "leaflet.locatecontrol";
import { LocationEvent } from "leaflet";
import { centroidType } from "./ressources";

export const LocateControl = ({
  setPosition,
  locatorUi,
  setLocatorUi,
  centroid,
}: {
  setPosition: (position: LocationEvent) => void;
  locatorUi: boolean;
  setLocatorUi: (locatorUi: boolean) => void;
  centroid: centroidType;
}) => {
  const map = useMap(); // Get map instance from parent MapContainer

  useMapEvents({
    click: () => {
      map.invalidateSize();
      map.locate();
    },

    locationfound: (location) => {
      setPosition(location);
      // Debugging:
      // console.log(
      //   "location found:",
      //   new Date(location.timestamp),
      //   "pos",
      //   location.latlng,
      //   "acc",
      //   location.accuracy
      // );
    },
  });

  useEffect(() => {
    const locateOptions = {
      position: "bottomright",
      drawCircle: false,
      clickBehavior: {
        inView: "setView", // default: stop -  don`t stop instead
        outOfView: "setView",
        inViewNotFollowing: "setView",
      },
      locateOptions: {
        enableHighAccuracy: true,
      },
      setView: "untilPanOrZoom", // Try out other options as "always" resets view
      strings: {
        title: "Zur Position",
      },
    };

    const locate = Locate as any;

    if (!locatorUi) {
      setLocatorUi(true);
      const lc = new locate(locateOptions);
      lc.addTo(map);
      lc.start();
    }
  }, [map]);

  return null;
};
