import { IonContent } from "@ionic/react";
import { ModalContent } from "src/ui/components/modalContent";
import { COLOR } from "../../../ui/variables";

import helpStations from "./img/intro.png";
import helpMap from "./img/helpMap.png";
import helpLocate from "./img/helpLocate.png";
import helpPos from "./img/helpPos.png";
import helpMapFooter from "./img/helpMapFooter.png";
import helpMapProgress from "./img/helpMapProgress.png";

export const HelpModal = ({
  setShowModal,
}: {
  setShowModal: (showModal: boolean) => void;
}) => {
  return (
    <ModalContent setShowModal={setShowModal} title="Anleitung" closable>
      <IonContent style={{ maxHeight: "80vh" }}>
        <img style={{ objectFit: "cover" }} src="/assets/helpscreen.svg" />
      </IonContent>
    </ModalContent>
  );
};

// Keeping this as a backup - can be removed
const not_used_helpText = () => (
  <>
    <div
      style={{
        padding: 10,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="helpFileHeading">Start</div>
      <p className="helpFileParagraph">
        Nachdem du dich für einen Spaziergang entschieden hast, siehst du hier
        die Stationen in der empfohlenen Reihenfolge:
      </p>
      <img
        src={helpStations}
        style={{
          height: "50%",
          width: "50%",
        }}
        className="helpFileImage"
      />
      <hr />
      <div className="helpFileHeading">Karte</div>
      <p className="helpFileParagraph">
        Die Karte zeigt dir die
        <span style={{ color: COLOR.positionDot }}> deinen Standort </span>
        und
        <span style={{ color: COLOR.routeDot }}> die Route</span>. Die Marker
        zeigen dir
        <span style={{ color: COLOR.routeVisitedStation }}>
          {" "}
          besuchten Orte{" "}
        </span>
        , die
        <span style={{ color: COLOR.routeNextStation }}> nächste Station </span>
        sowie alle darauf folgenden
        <span style={{ color: COLOR.routeUnvisitedStation }}>
          {" "}
          weiteren Stationen{" "}
        </span>
        des Spaziergangs.
      </p>
      <img src={helpMap} className="helpFileImage" />
      <hr />
      <p className="helpFileParagraph">
        Mit diesem Button wird deine aktuelle Position gefunden. Bitte stelle
        sicher, dass du GPS aktiviert hast!
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <img
          src={helpLocate}
          style={{
            height: "24%",
            width: "24%",
          }}
          className="helpFileImage"
        />
        <img
          style={{
            height: "25%",
            width: "25%",
          }}
          src={helpPos}
          className="helpFileImage"
        />
      </div>
      <hr />
      <p className="helpFileParagraph">
        In der Menüleiste wird dir die nächste Station angezeigt. Du kannst sie
        lokalisieren, oder mit dem kleinen Button (links) überspringen, wenn du
        sie nicht besuchen möchtest.
      </p>
      <img
        style={{
          height: "90%",
          width: "90%",
        }}
        src={helpMapFooter}
        className="helpFileImage"
      />
      <hr />
      <p className="helpFileParagraph">
        Sobald du in unmittelbarer Nähe einer Station bist, wirst du
        benachrichtigt. Du kannst eine Station auch aus der Ferne besuchen,
        indem du auf den Marker in der Karte klickst. Eindrücklicher ist es
        aber, vor Ort zu sein.
      </p>
      <p className="helpFileParagraph">
        Hier siehst du deinen Fortschritt während des Spaziergangs:
      </p>
      <img
        style={{
          height: "90%",
          width: "90%",
        }}
        src={helpMapProgress}
        className="helpFileImage"
      />
      <p className="helpFileParagraph">
        Wenn du auf diese Leiste klickst, siehst du nochmal die Stationen.
      </p>
      <hr />
      <div className="helpFileHeading">Station</div>
      <p className="helpFileParagraph">
        An den Stationen kannst du Texte Lesen, die Bilder Galerie öffnen oder
        das Quiz machen. Danach geht es mit dem Spaziergang weiter.
      </p>
      <p className="helpFileParagraph">
        Bitte gib uns am Ende des Spaziergangs Feedback damit wir die App
        verbessern können und über Fehler informiert werden.
      </p>
      <p className="helpFileParagraph">Viel Spass!</p>
    </div>
  </>
);
