import { GenericJsonArray } from "../importFromPublic"

const processKey = <K>(key: K, value: any) => {
    const truthy = ["ja", "Ja", "jA", "JA", "YES", "yes", "Yes"]
    const falsy = ["nein", "Nein", "NEIN", "NO", "no", "No"]

    // Parse string to boolean for VALUES  
    if (truthy.includes(value)) { return true }
    if (falsy.includes(value)) { return false }

    // Parse tags, based on delimiter, remove empty
    if (typeof value === "string" && value.includes(";")) {
        return value.split(";").filter((value: string) => value !== "").map(val => val.trim())
    }

    if (typeof key === "string") {
        // Parse KEYS depending on case
        switch (key) {
            case "lat":
            case "lon":
                return parseFloat(value);
            case "solution":
                return typeof (value) === "string" ? [value] : value;
            case "sliderstart":
            case "sliderend":
            case "slidertick":
            case "slidersolution":
                return typeof (value) === "string" ? parseInt(value) : value;
            default:
                return value
        }
    }
}

export const parseJson = <T, K extends keyof T>(jsonArray: GenericJsonArray): T[] =>
    jsonArray.map((jsonObj: { [key: string]: string | number | null }): T => {

        const jsonObjKeys = Object.keys(jsonObj)
        const result = {} as T

        jsonObjKeys.forEach(key => result[key as K] === undefined ? result[key as K] = processKey<K>(key as K, jsonObj[key]) : null)

        return result
    })
