import { useContext } from "react";
import { IonCardContent, IonText } from "@ionic/react";
import { IStation } from "src/data/model";
import { MdBlock } from "../../../data/parser/markdownParser";
import { CustomIonCard } from "../../../ui/other";
import { LexiconContext } from "src/context";
import { LexiconEnrichedText } from "../lexicon/lexiconModal";

export const DetailsCard = ({
  mdPathDetail,
  data,
  setShowLexiconModal,
}: {
  mdPathDetail: string;
  data: IStation;
  setShowLexiconModal: (state: boolean) => void;
}) => {
  return (
    <CustomIonCard>
      <IonCardContent>
        <div
          className="cardWrapper"
          style={{
            marginBottom: 16,
          }}
        >
          <IonText style={{ padding: 8 }}>
            <hr />
            <h1>{data.station_name}</h1>
            <h4>{data.function || ""}</h4>
            <h4>{data.construction_type || ""}</h4>
            <h4>{data.projectinfo || ""}</h4>
            <hr />
            <h4>{data.office}</h4>
            <LexiconEnrichedText
              predicate={data.architect}
              setShowLexiconModal={setShowLexiconModal}
            >
              <h4>{data.architect}</h4>
            </LexiconEnrichedText>

            <h4>{data.relation}</h4>
            <hr />
            <h4>{data.adress}</h4>
            <h4> {data.year_of_construction}</h4>
            <h4>{data.fact}</h4>
            <br />
            <LexiconEnrichedText
              predicate={data.owner}
              setShowLexiconModal={setShowLexiconModal}
            >
              <h4>{data.owner}</h4>
            </LexiconEnrichedText>
            <hr />
          </IonText>
          <MdBlock mdPath={mdPathDetail} />
        </div>
      </IonCardContent>
    </CustomIonCard>
  );
};
