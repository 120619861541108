import { useState, useEffect } from "react";
import { parseJson } from "./parser/jsonParser";

// If we import json from public (at runtime)
// we need the fetch API and cannot rely on TS type checking
// Types for clarification:
const stationPath = "/assets/data/test_station.json";

export type GenericJsonArray = { [key: string]: any }[];

export const ImportPublic = (): GenericJsonArray | undefined => {
  const [station, setStation] = useState<GenericJsonArray>(); // Change Type

  useEffect(() => {
    fetch(stationPath)
      .then((res): Promise<GenericJsonArray> => res.json())
      .then((data: GenericJsonArray) => setStation(parseJson(data)));
  }, []);

  return station;
};

// @ts-ignore
export const importAll = (path: string) => path.keys().map(path);

export const imagePaths = importAll(
  // @ts-ignore
  require.context("../../public/assets/Datenbank/", false, /\.(png|jpe?g|svg)$/)
);
