import { Map } from "leaflet";
import { Popup } from "react-leaflet";
import { StationAddress } from "src/ui/other";
import { FilledButton } from "../../../ui/buttons";
import { visitedStationsType } from "../../../context";
import { IStation, ITour } from "../../../data/model";
import { useIonRouter } from "@ionic/react";

export type MapMarkerPopupProps = {
  station: IStation;
  tour?: ITour;
  stationHasBeenVisited: (id: string) => visitedStationsType | undefined;
  markStationVisited: ({ id, index }: visitedStationsType) => void;
  mapRef?: Map;
};

export const MapMarkerPopup = ({
  station,
  tour,
  stationHasBeenVisited,
  markStationVisited,
  mapRef,
}: MapMarkerPopupProps) => {
  const router = useIonRouter();

  const routeFromPopup = () =>
    tour
      ? router.push(`/${tour.id}/station/${station.station_id}`)
      : router.push(`/station/${station.station_id}`);

  return (
    <Popup
      minWidth={200}
      autoPan
      closeOnEscapeKey
      closeOnClick
      closeButton={false}
      keepInView
      autoClose={false}
    >
      <StationAddress station={station} />
      <FilledButton
        text={
          stationHasBeenVisited(station.station_id)
            ? "Nochmal besuchen"
            : "Besuchen"
        }
        onClick={() => {
          markStationVisited &&
            markStationVisited({
              id: station.station_id,
              index: station.order || -1,
            });
          routeFromPopup();
          mapRef?.closePopup();
        }}
      />
    </Popup>
  );
};
