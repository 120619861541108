import { useState } from "react";
import {
  IonLabel,
  IonText,
  IonIcon,
  IonItem,
  useIonToast,
  IonButtons,
  IonToolbar,
} from "@ionic/react";
import {
  playForward,
  navigateCircleOutline,
  listOutline,
  chevronDown,
  chevronUp,
} from "ionicons/icons";
import { TextButton } from "src/ui/buttons";
import { Map } from "leaflet";
import { IStation } from "src/data/model";
import { COLOR } from "src/ui/variables";

type MapFooterProps = {
  allStationsVisited: (stations: IStation[]) => boolean;
  nextStation: IStation | undefined;
  stations: IStation[];
  mapRef: Map | undefined;
  nextStationIndex: number;
  markStationVisited: ({ id, index }: { id: string; index: number }) => void;
  setShowStationModal: (state: boolean) => void;
};

export const MapFooter = ({
  allStationsVisited,
  nextStation,
  stations,
  mapRef,
  nextStationIndex,
  markStationVisited,
  setShowStationModal,
}: MapFooterProps) => {
  const [present, dismiss] = useIonToast();
  const [menu, setMenu] = useState<boolean>(false);

  return (
    <div>
      {!allStationsVisited(stations) && nextStation?.station_id && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderTop: `1px solid ${COLOR.intermediatefont}`,
              borderBottom: `1px solid ${COLOR.lightfont}`,
            }}
          >
            <IonItem lines="none">
              <IonLabel>
                <p style={{ marginBottom: 4, width: "100vw" }}>
                  Nächste Station:
                </p>
                <IonText>
                  <span>{`${nextStationIndex}. `}</span>
                  {nextStation.station_name}
                </IonText>
                <h3>{nextStation.adress}</h3>
              </IonLabel>
            </IonItem>
            <TextButton
              size="large"
              text=""
              color="protored"
              icon={<IonIcon icon={menu ? chevronDown : chevronUp} />}
              onClick={() => setMenu(!menu)}
            />
          </div>
          {menu && (
            <IonToolbar>
              <IonButtons
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <TextButton
                  slot="start"
                  iconPosition="start"
                  text="Finden"
                  color="protored"
                  size="default"
                  icon={<IonIcon icon={navigateCircleOutline} />}
                  onClick={() => {
                    if (!mapRef) {
                      return;
                    }
                    mapRef.flyTo([nextStation.lat, nextStation.lon], 17);
                    mapRef.invalidateSize();
                  }}
                />
                <TextButton
                  slot="start"
                  iconPosition="start"
                  text="Überspringen"
                  color="protored"
                  size="default"
                  icon={<IonIcon icon={playForward} />}
                  onClick={() => {
                    present({
                      buttons: [
                        {
                          text: "Ja",
                          handler: () => {
                            markStationVisited({
                              id: nextStation.station_id,
                              index: nextStation.order || -1,
                            });
                            dismiss();
                          },
                        },
                        { text: "Nein", handler: () => dismiss() },
                      ],
                      position: "middle",
                      message: "Diese Station überspringen?",
                      color: "light",
                      cssClass: "toastSkipStation",
                    });
                  }}
                />
                <TextButton
                  slot="end"
                  iconPosition="start"
                  text="Übersicht"
                  color="protored"
                  size="default"
                  icon={<IonIcon icon={listOutline} />}
                  onClick={() => setShowStationModal(true)}
                />
              </IonButtons>
            </IonToolbar>
          )}
        </>
      )}
    </div>
  );
};
