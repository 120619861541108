import { OverviewCard } from "../content/overviewCard";
import { GAL_POS } from "../content/overviewCard";
import { IonIcon } from "@ionic/react";

import {
  helpCircleOutline,
  helpCircleSharp,
  linkOutline,
  openOutline,
} from "ionicons/icons";
import { COLOR } from "src/ui/variables";
import React, { useContext } from "react";
import { LexiconContext } from "src/context";
import { importAll } from "src/data/importFromPublic";
import { GALLERYTYPE } from "../uiElements/gallery";

const imagePaths = importAll(
  // @ts-ignore
  require.context(
    "../../../../public/assets/Lexikon/",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

export const LexiconModal = ({
  mdPathLexicon,
  entityId,
  entityName,
}: {
  mdPathLexicon: string;
  entityId: string;
  entityName: string;
}) => {
  const setAudioPlaying = () => false;
  return (
    <div style={{ overflowY: "scroll", textAlign: "center" }}>
      <OverviewCard
        mdPath={mdPathLexicon}
        galleryPosition={GAL_POS.BOTTOM}
        entityId={entityId}
        entityName={entityName}
        imagePaths={imagePaths}
        withTitle={false}
        galleryType={GALLERYTYPE.LEXICON}
        audioPlaying={false}
        setAudioPlaying={setAudioPlaying}
        hasAudioFile={false}
      >
        <div />
      </OverviewCard>
    </div>
  );
};

export const LexiconEnrichedText = ({
  predicate,
  setShowLexiconModal,
  children,
}: {
  predicate: string;
  setShowLexiconModal: (state: boolean) => void;
  children: React.ReactElement;
}) => {
  const { findInLexicon, setActiveEntryId } = useContext(LexiconContext);

  const entry = findInLexicon(predicate);

  return entry ? (
    <div
      style={{ display: "flex", cursor: "pointer" }}
      onClick={() => {
        setShowLexiconModal(true);
        setActiveEntryId(predicate);
      }}
    >
      {React.cloneElement(children, { className: "withEntry" })}

      <IonIcon
        style={{
          height: 18,
          width: 18,
          color: COLOR.protored,
        }}
        icon={openOutline}
      />
    </div>
  ) : (
    <div>{React.cloneElement(children, { className: "noEntry" })}</div>
  );
};
