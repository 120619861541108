// Use to import from public folder:
// import { ImportPublic } from "./importFromPublic";



/* ------------------ PRODUCTION ------------------ */
import { routes as Routes } from "src/data/data/routes";
import tours from "./data/trips.json";
import stations from "./data/stations.json";
import quizMult from "./data/quiz_mult.json";
import quizDiff from "./data/quiz_diff.json";
import quizSlider from "./data/quiz_slider.json";

import { ITour, IStation, IQuiz_Mult, IQuiz_Diff, IQuiz_Slider } from "./model";
import { parseJson } from "./parser/jsonParser";
import { lexiconParser } from "./parser/lexiconParser";

export const routes = Routes;
export const quizData_Mult = parseJson<IQuiz_Mult, keyof IQuiz_Mult>(quizMult);
export const quizData_Diff = parseJson<IQuiz_Diff, keyof IQuiz_Diff>(quizDiff);
export const quizData_Slider = parseJson<IQuiz_Slider, keyof IQuiz_Slider>(
  quizSlider
);

export const tourData = parseJson<ITour, keyof ITour>(tours);
export const stationData = parseJson<IStation, keyof IStation>(stations);
export const lexiconData = lexiconParser();

export const getStations = (tourId: string) => {
  // Filter tour json for the relevant tour:
  const thisTour = tourData.find((tour) => tour.id === tourId);

  const keysToExclude = ["id", "title", "input", "city", "canton"]; // these keys do not indicate stations and need to be excluded

  if (
    !thisTour ||
    keysToExclude.some((property) => !thisTour.hasOwnProperty(property))
  ) {
    console.error(
      "Error in content.ts: \n Invalid property in tour object detected, tour might not be load correctly. Update keys to fit interface in model.ts"
    );
    return;
  }

  // Get station ids and key as order out of the tour object:
  const extractedStations = Object.keys(thisTour)
    .filter((key) => !keysToExclude.includes(key)) // Filter out non-station keys
    .map((stationInTour) => ({
      id: thisTour[stationInTour],
      index: parseInt(stationInTour) || -1,
    }));

  // Find the corresponding station in stationData and load it
  const stations = extractedStations.map((extractedStation) => {
    let data = stationData.filter(
      (stationEntity) => stationEntity.station_id === extractedStation.id
    )[0];
    data.order = extractedStation.index;
    return data;
  });

  return stations || [];
};
