import { IonFooter } from "@ionic/react";
import { HEIGHT } from "../variables";

export const ActionFooter = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  return (
    <IonFooter
      style={{
        backgroundColor: "white",
        minHeight: HEIGHT.statusHeader,
        alignItems: "center",
        display: "flex",
        boxShadow:
          "rgba(0, 0, 0, 0.12) 0px -1px 3px, rgba(0, 0, 0, 0.24) 0px -1px 2px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        {children}
      </div>
    </IonFooter>
  );
};
