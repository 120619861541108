import { IonIcon } from "@ionic/react";
import ReactControl from "./genericLeafletControl";
import { reorderThreeOutline } from "ionicons/icons";
import { IconButton } from "src/ui/buttons";

export const CustomControl = ({
  setShowModal,
}: {
  setShowModal: (showModal: boolean) => void;
}) => {
  return (
    <ReactControl position="bottomleft">
      <div
        className="leaflet-control-zoom leaflet-bar leaflet-control"
        style={{
          bottom: 0,
          fontSize: 20,
          marginLeft: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          text=""
          icon={
            <IonIcon
              style={{
                color: "black",
                marginTop: 5,
              }}
              icon={reorderThreeOutline}
            />
          }
          onClick={setShowModal}
        />
      </div>
    </ReactControl>
  );
};
