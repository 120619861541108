// Uses the same coordinates and zoom as a Google Maps search (url field)
// Coordinates are not used, instead taken from the first station found.
export const CANTONS = [
    {
        name: "Aargau",
        coordinates: [47.47533, 8.30292],
        zoom: 14
    },
    {
        name: "Basel-Landschaft",
        coordinates: [47.5513555, 7.5986955],
        zoom: 11
    },
    {
        name: "Basel-Stadt",
        coordinates: [47.5513555, 7.5986955],
        zoom: 13
    },
    {
        name: "Bern",
        coordinates: [46.84809, 7.44744],
        zoom: 10
    },
    {
        name: "Freiburg",
        coordinates: [46.8045701, 7.1499416],
        zoom: 13
    },
    {
        name: "Genf",
        coordinates: [46.204882, 6.1018393],
        zoom: 12
    },
    {
        name: "Tessin",
        coordinates: [46.0981829, 8.9432666],
        zoom: 10
    },
    {
        name: "Waadt",
        coordinates: [46.86987, 6.5995647],
        zoom: 9
    },
    {
        name: "Wallis",
        coordinates: [46.280024, 7.3688344],
        zoom: 9
    },
    {
        name: "Zürich",
        coordinates: [47.4101099, 8.6026449],
        zoom: 11
    },
];

export type Canton = {
    name: string;
    coordinates: number[]
    zoom: number
}