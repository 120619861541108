import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

// Make sure to parse .md files and imgs from public folder
// so that they can be found with the fetchAPI
export const MdBlock = ({ mdPath }: { mdPath: string }) => {
  const [md, setMd] = React.useState<string>("");

  const parseableFallback = "<div></div>";

  React.useEffect(() => {
    fetch(mdPath)
      .then((res) => (res.status === 200 ? res.text() : parseableFallback))
      .then((res) => {
        return res.includes("<!DOCTYPE html>")
          ? setMd(parseableFallback)
          : setMd(res);
      })
      .catch((err) => console.error(err));
  });

  return (
    <div className="mdblob">
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{md}</ReactMarkdown>
    </div>
  );
};
