import { Icon } from "leaflet";
import "leaflet-iconmaterial";

import { COLOR } from "./variables";

const radioButtonOn = "/assets/icon/radio-button-on.svg";

export const userPositionIcon = new Icon({
  iconUrl: radioButtonOn,
  iconSize: [40, 40],
});
// @ts-expect-error:
export const stationIcon = L.IconMaterial.icon({
  icon: "",
  iconColor: COLOR.routeUnvisitedStation,
  markerColor: COLOR.routeUnvisitedStation,
  outlineColor: "black",
  outlineWidth: 1,
  iconSize: [30, 30],
});
// @ts-expect-error:
export const stationNextIcon = L.IconMaterial.icon({
  icon: "",
  iconColor: COLOR.routeNextStation,
  markerColor: COLOR.routeNextStation,
  outlineColor: "black",
  outlineWidth: 1,
  iconSize: [30, 30],
});
// @ts-expect-error:
export const stationVisitedIcon = L.IconMaterial.icon({
  icon: "",
  iconColor: COLOR.routeVisitedStation,
  markerColor: COLOR.routeVisitedStation,
  outlineColor: "black",
  outlineWidth: 1,
  iconSize: [30, 30],
});
