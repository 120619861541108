import "swiper/css";

import { GAL_POS, OverviewCard, InfoBanner } from "./overviewCard";
import { DetailsCard } from "./detailsCard";
import { IStation } from "../../../data/model";
import { imagePaths } from "src/data/importFromPublic";
import { GALLERYTYPE } from "../uiElements/gallery";

type ContentComponentProps = {
  stationData: IStation;
  mdPathOverview: string;
  mdPathDetail: string;
  activeTab: number;
  setShowLexiconModal: (state: boolean) => void;
  audioPlaying: boolean;
  setAudioPlaying: (state: boolean) => void;
  hasAudioFile: boolean;
};

export const ContentComponent = ({
  stationData,
  mdPathOverview,
  mdPathDetail,
  activeTab,
  setShowLexiconModal,
  audioPlaying,
  setAudioPlaying,
  hasAudioFile,
}: ContentComponentProps) => {
  switch (activeTab) {
    case 0:
      return (
        <OverviewCard
          mdPath={mdPathOverview}
          galleryPosition={GAL_POS.TOP}
          entityId={stationData.station_id}
          entityName={stationData.station_name}
          imagePaths={imagePaths}
          galleryType={GALLERYTYPE.STATION}
          audioPlaying={audioPlaying}
          setAudioPlaying={setAudioPlaying}
          hasAudioFile={hasAudioFile}
          withTitle
        >
          <InfoBanner
            data={stationData}
            setShowLexiconModal={setShowLexiconModal}
          />
        </OverviewCard>
      );
    case 1:
      return (
        <>
          <DetailsCard
            data={stationData}
            mdPathDetail={mdPathDetail}
            setShowLexiconModal={setShowLexiconModal}
          />
          ;
        </>
      );
    default:
      return <div>Tab not found</div>;
  }
};
