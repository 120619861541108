import { useContext } from "react";
import { IonIcon, IonPage, IonText } from "@ionic/react";
import { trophy } from "ionicons/icons";

import { TourContext } from "../../context";
import { FilledButton } from "../../ui/buttons";
import { ActionFooter } from "../../ui/components/actionFooter";

export const FinishComponent = () => {
  const tour = useContext(TourContext).getActiveTour();

  return (
    <IonPage>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          backgroundColor: "#8da760",
        }}
      >
        <IonText
          style={{
            color: "white",
            marginBottom: -20,
            marginTop: 20,
            fontSize: 30,
            fontWeight: 700,
          }}
        >
          Fertig!
        </IonText>

        <div
          style={{
            height: 250,
            width: 250,
            background: "white",
            border: "2px solid #8da760",
            borderRadius: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IonIcon
            style={{
              height: 100,
              width: 100,
              color: "#4e6421",
            }}
            icon={trophy}
          />
        </div>
        <IonText
          style={{
            color: "white",
            textAlign: "center",
            marginTop: 20,
            fontSize: 16,
          }}
        >
          <p>Das war:</p>
          <h5>{tour.title}</h5>
        </IonText>
      </div>
      <ActionFooter>
        <FilledButton text="Neue Tour" color="darkfont" routerLink="/landing" />
      </ActionFooter>
    </IonPage>
  );
};
