import { FilledButton, OutlinedButton } from "../../../ui/buttons";
import { arrowForward } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

type StationFooterProps = {
  quizCollectionLength: number;
  stationLength: number;
  visitedStationsLength: number;
  noTour: boolean;
  setShowQuizModal: (state: boolean) => void;
  setActiveTab: (state: number) => void;
  id: string;
};

export const StationFooter = ({
  quizCollectionLength,
  stationLength,
  visitedStationsLength,
  noTour,
  setShowQuizModal,
  setActiveTab,
  id,
}: StationFooterProps) => (
  <>
    {quizCollectionLength > 0 ? (
      <OutlinedButton
        style={{
          height: 40,
          minWidth: 160,
        }}
        text="Quiz spielen"
        onClick={() => setShowQuizModal(true)}
      />
    ) : null}
    {stationLength === visitedStationsLength ? (
      <FilledButton
        icon={<IonIcon slot="end" icon={arrowForward} />}
        routerLink={`/${id}/end`}
        style={{
          height: 40,
          minWidth: 180,
        }}
        text="Beenden"
      />
    ) : (
      <FilledButton
        icon={<IonIcon slot="end" icon={arrowForward} />}
        routerLink={noTour ? `/contentselectionMap` : `/${id}/map`}
        style={{
          height: 40,
          minWidth: 180,
        }}
        text="Weiter"
        onClick={() => setActiveTab(0)}
      />
    )}
  </>
);
