import { useContext } from "react";
import { IonButton, IonIcon, IonLabel, IonText } from "@ionic/react";
import { LatLngTuple } from "leaflet";
import { Map } from "leaflet";
import { CANTONS, Canton } from "src/data/data/cantons";
import { TourContext } from "src/context";
import { IStation } from "src/data/model";
import { TextButton } from "src/ui/buttons";
import { chevronDown, chevronUp } from "ionicons/icons";

export const QuickSelect = ({
  mapRef,
  canton,
  setCanton,
  quickSelectOpen,
  setQuickSelect,
}: {
  mapRef?: Map;
  canton: Canton;
  setCanton: (canton: Canton) => void;
  quickSelectOpen: boolean;
  setQuickSelect: (state: boolean) => void;
}) => {
  const stations = useContext(TourContext).getStations() || [];

  const cantonNames = [
    ...new Set(stations.map((station) => station.canton)),
  ].sort((a: string, b: string) => a.localeCompare(b));

  const cantons = cantonNames.map((name) => {
    const cantonFromList = CANTONS.find((C) => C.name === name);
    if (cantonFromList) {
      return {
        lat: cantonFromList.coordinates[0],
        lon: cantonFromList.coordinates[1],
        name,
      };
    } else {
      // Takes frst station as reference point:
      const { lat, lon } = stations.find(
        (station) => station.canton === name
      ) as IStation;
      return { lat, lon, name };
    }
  });

  return (
    <>
      <div className="quickSelectWrapper">
        <FooterToggle {...{ quickSelectOpen, setQuickSelect }} />
        {quickSelectOpen ? (
          <div className="quickSelectWrapperInner">
            {cantons.map((thisCanton, index) => (
              <IonButton
                id={thisCanton.name}
                key={index}
                className="LandingPageButton"
                color="white" // important
                style={{
                  color: thisCanton.name === canton.name ? "white" : "#A31111",
                  backgroundColor:
                    thisCanton.name === canton.name ? "#A31111" : "white",
                  width: "100%",
                  border: "1px solid #C0C0C0",
                  borderRadius: 10,
                  margin: "6px 0px",
                }}
                onClick={() => {
                  const coordinates = [
                    thisCanton.lat,
                    thisCanton.lon,
                  ] as LatLngTuple;
                  const zoom = CANTONS.find(
                    (canton) => canton.name === thisCanton.name
                  )
                    ? CANTONS.find((canton) => canton.name === thisCanton.name)
                        ?.zoom
                    : (13 as number);
                  mapRef?.setView(coordinates as LatLngTuple, zoom);
                  setCanton({
                    name: thisCanton.name,
                    coordinates,
                    zoom: zoom ? zoom : 13,
                  });
                }}
              >
                {thisCanton.name}
              </IonButton>
            ))}
          </div>
        ) : (
          <div />
        )}
      </div>
    </>
  );
};

const FooterToggle = ({
  quickSelectOpen,
  setQuickSelect,
}: {
  quickSelectOpen: boolean;
  setQuickSelect: (state: boolean) => void;
}) => (
  <div
    style={{
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    }}
    onClick={() => setQuickSelect(!quickSelectOpen)}
  >
    <div style={{ width: 60 }}></div>
    <IonLabel>
      <IonText color="protored">Kanton auswählen</IonText>
    </IonLabel>
    <TextButton
      size="large"
      text=""
      color="protored"
      icon={<IonIcon icon={quickSelectOpen ? chevronDown : chevronUp} />}
      onClick={() => setQuickSelect(!quickSelectOpen)}
    />
  </div>
);
