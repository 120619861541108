import { useState, useContext, useEffect } from "react";
import { MapContainer, Marker, TileLayer, ZoomControl } from "react-leaflet";
import { TourContext, ProgressContext } from "../../context";
import { BASEMAPS, centroidType } from "../map/functionality/ressources";
import { LocateControl } from "../map/functionality/locateControl";
import { LatLngTuple, LocationEvent } from "leaflet";
import { Map } from "leaflet";
import { stationIcon, stationVisitedIcon } from "../../ui/icons";
import { MapMarkerPopup } from "../map/uiElements/mapMarkerPopup";
import { ContentWrapper } from "./uiElements/contentWrapper";
import { TourSelectionComponent } from "../tourSelection/tourSelectionComponent";
import { CANTONS, Canton } from "src/data/data/cantons";
import { CategorySelect } from "../map/uiElements/categorySelect";
import { IStation } from "src/data/model";

export type ContentSelection = {
  setContentMapRef: (mapRef: Map) => void;
  contentMapRef?: Map;
  setContentTab: (n: number) => void;
  canton: Canton;
  setCanton: (canton: Canton) => void;
  quickSelectOpen: boolean;
  setQuickSelect: (state: boolean) => void;
};

export const ContentSelectionTour = ({
  setContentMapRef,
  contentMapRef,
  setContentTab,
  canton,
  setCanton,
  quickSelectOpen,
  setQuickSelect,
}: ContentSelection) => {
  return (
    <ContentWrapper
      contentTab={1}
      {...{
        setContentMapRef,
        contentMapRef,
        setContentTab,
        canton,
        setCanton,
        quickSelectOpen,
        setQuickSelect,
      }}
    >
      <div
        style={{
          flex: 1,
        }}
      >
        <TourSelectionComponent canton={canton} />
      </div>
    </ContentWrapper>
  );
};

export const ContentSelectionMap = ({
  setContentMapRef,
  contentMapRef,
  setContentTab,
  canton,
  setCanton,
  quickSelectOpen,
  setQuickSelect,
}: ContentSelection) => {
  const stations = useContext(TourContext).getStations() || [];

  const [position, setPosition] = useState<LocationEvent>();
  const defaultCanton = CANTONS.find((canton) => canton.name === "Basel-Stadt");
  const defaultCentroid = defaultCanton?.coordinates as LatLngTuple;
  const [centroid, setCentroid] = useState<centroidType>(defaultCentroid);
  const [locatorUi, setLocatorUi] = useState<boolean>(false);
  const [activeCategories, setActiveCategories] = useState<string[]>([]);

  const setCategory = (category: string) =>
    activeCategories.includes(category)
      ? setActiveCategories(activeCategories.filter((cat) => cat != category))
      : setActiveCategories([...activeCategories, category]);

  contentMapRef &&
    contentMapRef.on("locationerror", () =>
      contentMapRef.setView(defaultCentroid, 12)
    );

  const selectedStations =
    activeCategories.length < 1
      ? stations
      : stations.filter((station) =>
          activeCategories.includes(station.functionfilter)
        );

  return (
    <>
      <ContentWrapper
        contentTab={0}
        {...{
          contentMapRef,
          setContentTab,
          canton,
          setCanton,
          quickSelectOpen,
          setQuickSelect,
        }}
      >
        <>
          <CategorySelect {...{ stations, activeCategories, setCategory }} />
          <MapContainer
            zoomControl={false} // Use ZoomControl component below instead
            center={defaultCentroid}
            scrollWheelZoom={false}
            style={{
              flex: 1,
            }}
            ref={setContentMapRef}
          >
            <ZoomControl position="bottomleft" />

            <LocateControl
              setPosition={setPosition}
              locatorUi={locatorUi}
              setLocatorUi={setLocatorUi}
              centroid={centroid}
            />
            <TileLayer
              className="leafletBasemap"
              attribution={BASEMAPS.osm.attribution}
              url={BASEMAPS.osm.url}
            />
            <Stations mapRef={contentMapRef} stations={selectedStations} />
          </MapContainer>
        </>
      </ContentWrapper>
    </>
  );
};

const Stations = ({
  mapRef,
  stations,
}: {
  mapRef?: Map;
  stations: IStation[];
}) => {
  const { markStationVisited, visitedStations } = useContext(ProgressContext);

  const stationHasBeenVisited = (stationId: string) =>
    visitedStations.find((station) => station.id === stationId);

  return (
    <>
      {stations.map((station) => (
        <Marker
          key={station.station_id}
          position={[station.lat, station.lon] as LatLngTuple}
          icon={
            stationHasBeenVisited(station.station_id)
              ? stationVisitedIcon
              : stationIcon
          }
        >
          <MapMarkerPopup
            station={station}
            stationHasBeenVisited={stationHasBeenVisited}
            markStationVisited={markStationVisited}
            mapRef={mapRef}
          />
        </Marker>
      ))}
    </>
  );
};
