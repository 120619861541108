import { IonChip, IonIcon, IonLabel } from "@ionic/react";
import { COLOR } from "src/ui/variables";
import { IStation } from "src/data/model";
import { close } from "ionicons/icons";

export const CategorySelect = ({
  stations,
  activeCategories,
  setCategory,
}: {
  stations: IStation[];
  activeCategories: string[];
  setCategory: (categories: string) => void;
}) => {
  const allCategories = [
    ...new Set(stations.map((station) => station.functionfilter)),
  ];

  const availableCategoriesList = [
    "Umbau",
    "Aufstockung",
    "Mixed-Use",
    "Wohnen",
    "Bildung",
    "Sport",
    "Büro",
    "Gesundheit",
  ];

  const availableCategories = allCategories.filter((category) =>
    availableCategoriesList.includes(category)
  );

  return (
    <div
      style={{
        top: 80,
        marginTop: 20,
        width: "100%",
        height: 50,
        position: "absolute",
        zIndex: 99999,
        display: "flex",
        flexFlow: "row nowrap",
        overflowX: "scroll",
      }}
    >
      {availableCategories.map((category: string) => (
        <IonChip
          onClick={() => setCategory(category)}
          outline={true}
          key={category}
          style={{
            backgroundColor: activeCategories.includes(category)
              ? COLOR.protored
              : "white",
            display: "flex",
            flexDirection: "row",
            wordBreak: "unset",
            overflow: "hidden",
            inlineSize: "minContent",

            justifyContent: "space-between",
            color: activeCategories.includes(category)
              ? "white"
              : COLOR.protored,
            minWidth: 124,
            minHeight: 30,
          }}
        >
          <IonLabel style={{ overflow: "hidden", inlineSize: "minContent" }}>
            {category === "Sondernutzungen" ? "Sondern.." : category}
          </IonLabel>
          <IonIcon
            color={
              activeCategories.includes(category) ? "white" : COLOR.protored
            }
            icon={close}
          ></IonIcon>
        </IonChip>
      ))}
    </div>
  );
};
