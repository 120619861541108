import { IonRow, useIonRouter } from "@ionic/react";
import { TextButton } from "../buttons";
import { IStation } from "../../data/model";
import { COLOR, HEIGHT } from "../variables";

type TabBarContentProps = {
  activeTab: number;
  setActiveTab: (activeTab: number) => void;
};

type TabBarProps = {
  stationData?: IStation;
  tabDefinition: string[];
  setAudioPlaying: (state: boolean) => void;
} & TabBarContentProps;
type TabButtonProps = {
  activeTab: number;
  thisTab: number;
  setActiveTab: (activeTab: number) => void;
  setAudioPlaying: (state: boolean) => void;
  stationData?: IStation;
  text: string;
};

export const TabButton = ({
  activeTab,
  setActiveTab,
  text,
  thisTab,
  setAudioPlaying,
}: TabButtonProps) => (
  <TextButton
    color={activeTab === thisTab ? "protored" : "intermediatefont"}
    onClick={() => {
      setAudioPlaying(false);
      setActiveTab(thisTab);
    }}
    style={{
      borderBottom:
        activeTab === thisTab
          ? `2px solid ${COLOR.protored}`
          : `2px solid ${COLOR.intermediatefont}`,
    }}
    text={text}
  />
);

export const TabBar = ({
  activeTab,
  setActiveTab,
  tabDefinition,
  setAudioPlaying,
}: TabBarProps) => (
  <IonRow>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        margin: "0 20px",
        minHeight: HEIGHT.tabBar,
      }}
    >
      {tabDefinition.map((tab, index) => (
        <TabButton
          key={index}
          thisTab={index}
          text={tab}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setAudioPlaying={setAudioPlaying}
        />
      ))}
    </div>
  </IonRow>
);
export const TabButton2 = ({
  activeTab,
  setActiveTab,
  text,
  thisTab,
}: TabButtonProps) => {
  const router = useIonRouter();

  return (
    <TextButton
      color={activeTab === thisTab ? "protored" : "intermediatefont"}
      onClick={() => {
        setActiveTab(thisTab);
        activeTab == 1
          ? router.push("/contentSelectionMap")
          : router.push("/contentSelectionTour");
      }}
      style={{
        borderBottom:
          activeTab === thisTab
            ? `2px solid ${COLOR.protored}`
            : `2px solid ${COLOR.intermediatefont}`,
      }}
      text={text}
    />
  );
};

export const TabBarContent = ({
  activeTab,
  setActiveTab,
}: TabBarContentProps) => {
  const router = useIonRouter();
  return (
    <IonRow>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          margin: "6px 20px",
          minHeight: HEIGHT.tabBar,
        }}
      >
        <TextButton
          color={activeTab == 0 ? "protored" : "intermediatefont"}
          onClick={() => {
            setActiveTab(0);

            router.push("/contentSelectionMap");
          }}
          style={{
            borderBottom:
              activeTab === 0
                ? `2px solid ${COLOR.protored}`
                : `2px solid ${COLOR.intermediatefont}`,
          }}
          text={"Gebäude"}
        />
        <TextButton
          color={activeTab === 1 ? "protored" : "intermediatefont"}
          onClick={() => {
            setActiveTab(1);
            router.push("/contentSelectionTour");
          }}
          style={{
            borderBottom:
              activeTab === 1
                ? `2px solid ${COLOR.protored}`
                : `2px solid ${COLOR.intermediatefont}`,
          }}
          text={"Spaziergänge"}
        />
      </div>
    </IonRow>
  );
};
