import { useContext, useState, useEffect, useRef } from "react";
import { IonPage, useIonRouter, IonActionSheet, IonModal } from "@ionic/react";
import { LocationEvent, Map } from "leaflet";
import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  ZoomControl,
} from "react-leaflet";
import { RoutingMachine } from "./functionality/routingMachine";
import { LocateControl } from "./functionality/locateControl";
import { Haptics } from "@capacitor/haptics";

import { Progressbar } from "./uiElements/progressBar";
import { TourDefaultHeader } from "../../ui/components/statusHeader";
import { IStation } from "../../data/model";
import { routes } from "../../data/content";
import { ProgressContext, TourContext } from "../../context";
import {
  BASEMAPS,
  calcCentroid,
  calcBbox,
  centroidType,
  bboxType,
  RouteType,
} from "./functionality/ressources";
import {
  stationIcon,
  stationVisitedIcon,
  stationNextIcon,
} from "../../ui/icons";

import { BREAKPOINT, COLOR } from "src/ui/variables";
import { ModalContent } from "src/ui/components/modalContent";
import { HelpModal } from "../landingPage/help/helpModal";
import { StationList } from "../introduction/introductionComponent";
import { CustomControl } from "./functionality/customLeafletControl";
import { MapMarkerPopup } from "./uiElements/mapMarkerPopup";
import { MapFooter } from "./uiElements/mapFooter";

const MapComponent = ({
  setMapReference,
  mapRef,
}: {
  setMapReference: (mapRef: Map) => void;
  mapRef?: Map;
}) => {
  const { visitedStations, markStationVisited } = useContext(ProgressContext);
  const tour = useContext(TourContext).getActiveTour();
  const stations = useContext(TourContext).getCurrentStations();

  const [route, setRoute] = useState<RouteType>();
  const [position, setPosition] = useState<LocationEvent>();
  const [centroid, setCentroid] = useState<centroidType>([0, 0]);
  const [bbox, setBbox] = useState<bboxType>([
    [0, 0],
    [0, 0],
  ]);
  const [locatorUi, setLocatorUi] = useState<boolean>(false);
  const [actionSheet, showActionSheet] = useState<boolean>(false);
  const [showStationModal, setShowStationModal] = useState<boolean>(false);
  const [showHelpModal, setShowHelpModal] = useState(false);

  const [activeFenceMatch, setActiveFenceMatch] = useState<string>("");
  const [nextStation, setNextStation] = useState<IStation>();
  const [nextStationIndex, setNextStationIndex] = useState<number>(0);
  const modal = useRef<HTMLIonModalElement>(null);

  const router = useIonRouter();

  const calcGeofences = () => {
    // Adjust for geo"fencing" around stations:
    const latFactor = 0.00028;
    const lonFactor = 0.0004;
    // const latFactor = 0.00045; // For debugging
    // const lonFactor = 0.0002; // For debugging

    const geofences: { id: string; fence: [number, number][] }[] = stations.map(
      (station) => {
        const { lat, lon } = station;

        return {
          id: station.station_id,
          fence: [
            [lat - latFactor, lon - lonFactor],
            [lat + latFactor, lon + lonFactor],
          ],
        };
      }
    );
    return geofences;
  };

  // Update map container in case of resizing:
  useEffect(() => {
    mapRef && mapRef.invalidateSize();
  }, [visitedStations.length, stations.length]);

  // Track position changes and report match events
  useEffect(() => {
    if (!position || !position.latlng) {
      return;
    }
    const { lat, lng } = position.latlng;

    const hit = calcGeofences().find(
      (geofence) =>
        lat > geofence.fence[0][0] &&
        lat < geofence.fence[1][0] &&
        lng > geofence.fence[0][1] &&
        lng < geofence.fence[1][1]
    );

    if (!hit) {
      setActiveFenceMatch("");
      showActionSheet(false);
    }

    if (hit && hit.id !== activeFenceMatch) {
      console.log(
        "Found hit, not equal active fence",
        hit.id,
        activeFenceMatch
      );
      setActiveFenceMatch(hit.id);
      showActionSheet(true);
      Haptics.vibrate({ duration: 2000 });
    }
  }, [position?.latlng.lat, position?.latlng.lng]);

  // Force leaflet to update map when new tour is selected:
  useEffect(() => {
    setCentroid(calcCentroid(stations));
    setBbox(calcBbox(stations));
    setRoute(
      routes.find((route) => route.id === tour.id) || {
        id: "",
        path: [],
        mTime: 0,
        mDistance: 0,
        dTime: "",
      }
    );
  }, [tour.id]);

  useEffect(() => {
    const updateNextStation = (station: IStation) => {
      setNextStation(station);

      setNextStationIndex(
        stations.findIndex(
          (routeStations) => routeStations.station_id === station.station_id
        ) + 1
      );
    };
    // Find next station in tour
    // First strategy: Select first station in tour
    if (visitedStations.length < 1) {
      const firstStation = stations.find((station) => station.order === 1)!;
      firstStation ? updateNextStation(firstStation) : null;
      return;
    }

    const visistedStationIds = visitedStations.map((vStation) => vStation.id);

    const highestVisited = visitedStations.sort((a, b) => b.index - a.index)[0];
    const nextHigherStation = stations.find(
      (station) => station.order === highestVisited.index + 1
    );
    // Second strategy: Get next station in order, unless already visited:
    if (
      nextHigherStation &&
      !visistedStationIds.includes(nextHigherStation.station_id)
    ) {
      updateNextStation(nextHigherStation);
      return;
    }
    // Third strategy: Get a random unvisited station:
    const someOtherStation = stations.find(
      (station) => !visistedStationIds.includes(station.station_id)
    );
    if (someOtherStation) {
      updateNextStation(someOtherStation);
      return;
    }
    return;
  }, [tour.id, visitedStations.length]);

  const stationHasBeenVisited = (stationId: string) =>
    visitedStations.find((station) => station.id === stationId);

  const allStationsVisited = (stations: IStation[]) =>
    stations.every((station) =>
      visitedStations.find(
        (visitedStation) => visitedStation.id === station.station_id
      )
    );

  return (
    <IonPage>
      <IonModal
        ref={modal}
        isOpen={showStationModal}
        initialBreakpoint={BREAKPOINT}
      >
        <ModalContent
          setShowModal={setShowStationModal}
          title="Übersicht"
          closable
        >
          <StationList stations={stations} tour={tour} />
        </ModalContent>
      </IonModal>
      <IonModal
        ref={modal}
        isOpen={showHelpModal}
        initialBreakpoint={BREAKPOINT}
      >
        <HelpModal setShowModal={setShowHelpModal} />
      </IonModal>
      <TourDefaultHeader />
      <Progressbar
        visitedStationsCount={visitedStations.length}
        stationCount={stations.length}
        tourTitle={tour.title}
        onClick={() => setShowStationModal(true)}
      />
      <IonActionSheet
        isOpen={actionSheet}
        cssClass="actionSheetStationNear"
        header={`${
          stations.find((station) => station.station_id === activeFenceMatch)
            ?.station_name
        }`}
        buttons={[
          {
            text: "Station besuchen",
            role: "destructive",
            id: "stationButton",
            handler: () => {
              const matchingStation = stations.find(
                (station) => station.station_id === activeFenceMatch
              )!;

              markStationVisited({
                id: matchingStation.station_id,
                index: matchingStation.order || -1,
              }); // This needs updating when geofencing is added.
              router.push(`/${tour.id}/station/${matchingStation.station_id}`);
            },
          },
          {
            text: "Überspringen",
            id: "stationButtonIgnore",
            handler: () => {
              const matchingStation = stations.find(
                (station) => station.station_id === activeFenceMatch
              )!;

              markStationVisited({
                id: matchingStation.station_id,
                index: matchingStation.order || -1,
              }); // This needs updating when geofencing is added.
            },
          },
        ]}
      />
      <div className="mapWrapper">
        {centroid[0] && bbox[0] && (
          <MapContainer
            zoomControl={false} // Use ZoomControl component below instead
            minZoom={12}
            center={centroid}
            bounds={bbox}
            scrollWheelZoom={false}
            style={{
              flex: 1,
            }}
            ref={setMapReference}
          >
            <CustomControl setShowModal={() => setShowHelpModal(true)} />
            <ZoomControl position="bottomleft" />
            <LocateControl
              setPosition={setPosition}
              locatorUi={locatorUi}
              setLocatorUi={setLocatorUi}
              centroid={centroid}
            />
            {/* Leave disabled in prod-environment: */}
            {/* <RoutingMachine
              tourId={tour.id}
              waypoints={stations.map(
                (station) => [station.lat, station.lon],
                5
              )}
            /> */}
            <TileLayer
              className="leafletBasemap" // Deactivate for colored basemap
              attribution={BASEMAPS.osm.attribution}
              url={BASEMAPS.osm.url}
            />
            {route && route.id && (
              <Polyline
                pathOptions={{
                  color: COLOR.routeDotOutline,
                  weight: 10,
                  dashArray: "1, 20 ",
                }}
                noClip={true}
                positions={route.path}
              />
            )}
            {route && route.id && (
              <Polyline
                pathOptions={{
                  color: COLOR.routeDot,
                  weight: 8,
                  dashArray: "1, 20 ",
                }}
                noClip={true}
                positions={route.path}
              />
            )}
            {/* Show geofences - disable in production */}
            {/* {calcGeofences().map((geofence, index: number) => (
              <Rectangle
                key={index}
                bounds={geofence.fence}
                pathOptions={{ color: "#9932CC" }}
              />
            ))} */}
            {stations.map((station, index) => (
              <Marker
                key={index}
                position={[station.lat, station.lon]}
                icon={
                  stationHasBeenVisited(station.station_id)
                    ? stationVisitedIcon
                    : station.station_id === nextStation!.station_id
                    ? stationNextIcon
                    : stationIcon
                }
              >
                <MapMarkerPopup
                  {...{
                    mapRef,
                    station,
                    tour,
                    stationHasBeenVisited,
                    markStationVisited,
                  }}
                />
              </Marker>
            ))}
          </MapContainer>
        )}
        <MapFooter
          {...{
            allStationsVisited,
            nextStation,
            stations,
            mapRef,
            nextStationIndex,
            markStationVisited,
            setShowStationModal,
          }}
        />
      </div>
    </IonPage>
  );
};
export default MapComponent;
