import ReactAudioPlayer from "react-audio-player";

import { close } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

export const AudioFooter = ({
  audioFile,
  setAudioPlaying,
}: {
  audioFile: string;
  setAudioPlaying: (state: boolean) => void;
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <ReactAudioPlayer
        src={audioFile}
        autoPlay
        controls
        onEnded={() => setAudioPlaying(false)}
      />
      <IonIcon
        id="audio-stop-footer"
        slot="start"
        src={close}
        onClick={() => setAudioPlaying(false)}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};
