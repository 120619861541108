import React from "react";
import { IonIcon } from "@ionic/react";
import Lightbox from "react-image-lightbox";
import stationCaptions from "../../../data/data/captions.json";
import lexiconCaptions from "../../../data/data/captions_lexicon.json";
import {
  images as imagesIcon,
  play as playIcon,
  close as closeIcon,
} from "ionicons/icons";
import "react-image-lightbox/style.css";

export enum GALLERYTYPE {
  STATION = "STATION",
  LEXICON = "LEXICON",
}

type Caption = {
  picture_id: string;
  text: string;
};

type LexiconCaption = {
  lexicon_id: string;
} & Caption;

type StationCaption = {
  lexicon_id: string;
} & Caption;

export const Gallery = ({
  id,
  imagePaths,
  galleryType,
  audioPlaying,
  setAudioPlaying,
  hasAudioFile,
}: {
  id: string;
  imagePaths: string[];
  galleryType: GALLERYTYPE;
  audioPlaying: boolean;
  setAudioPlaying: (state: boolean) => void;
  hasAudioFile: boolean;
}) => {
  const [photoIndex, setPhotoIndex] = React.useState<number>(0);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const selectedCaptions =
    galleryType === GALLERYTYPE.LEXICON
      ? lexiconCaptions.filter(
          (lexiconCaptions) => lexiconCaptions.lexicon_id === id
        )
      : stationCaptions.filter(
          (stationCaption) => stationCaption.station_id === id
        );

  const findId = (path: string) => {
    const firstPosition = path.search(id);
    const getFileType = () => {
      if (path.search(".png") || path.search(".PNG")) {
        return ".png";
      }
      if (path.search(".jpg") || path.search(".JPG")) {
        return ".jpg";
      }
      if (path.search(".jpeg") || path.search(".JPEG")) {
        return ".jpg";
      }
      if (path.search(".svg") || path.search(".SVG")) {
        return ".svg";
      }
      return "";
    };
    const thisId = path
      .slice(firstPosition, firstPosition + id.length + 3)
      .concat(getFileType());

    const targetCaption = (
      selectedCaptions as StationCaption[] | LexiconCaption[]
    ).find(
      (caption: StationCaption | LexiconCaption) =>
        caption.picture_id === thisId.slice(0, -4)
    );
    return targetCaption?.text || "";
  };
  const images = imagePaths
    .filter((path: string) => path.includes(id)) // only station specific
    .filter((path: string) => !path.includes("Slider")); // No slider images

  return (
    <div>
      <div className="gallery">
        <img
          style={{ maxHeight: "50vh", margin: "0 auto" }}
          src={images[0]}
          onClick={() => setIsOpen(true)}
        />
        {images && images.length > 1 && (
          <IonIcon
            id="gallery-overlay"
            src={imagesIcon}
            onClick={() => setIsOpen(true)}
            style={{ cursor: "pointer" }}
          />
        )}

        {audioPlaying && hasAudioFile ? (
          <IonIcon
            id="audio-overlay"
            src={closeIcon}
            onClick={() => {
              setAudioPlaying(false);
            }}
            style={{ cursor: "pointer" }}
          />
        ) : !audioPlaying && hasAudioFile ? (
          <IonIcon
            id="audio-overlay"
            src={playIcon}
            onClick={() => setAudioPlaying && setAudioPlaying(true)}
            style={{ cursor: "pointer" }}
          />
        ) : null}
      </div>

      {isOpen && (
        <Lightbox
          imageCaption={<div>{findId(images[photoIndex])}</div>}
          imagePadding={0}
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};
