import { useEffect } from "react";
import { IonPage, useIonRouter } from "@ionic/react";

import loadingImgSvg from "./splash.svg";

export const LoadingComponent = () => {
  const router = useIonRouter();

  useEffect(() => {
    setTimeout(() => router.push("/landing", "none", "replace"), 2000);
  }, []);

  return (
    <IonPage>
      <div
        style={{
          minWidth: "100vw",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={loadingImgSvg}
          alt="loading..."
          style={{
            minHeight: "100%",
            objectFit: "cover",
          }}
        />
      </div>
    </IonPage>
  );
};
