import { useState, useEffect } from "react";
import { IonCheckbox, IonList, IonItem, IonLabel, IonText } from "@ionic/react";
import { IQuiz_Mult, IQuiz_Diff } from "../../../data/model";
import { QuizQuestion } from "../../../ui/titles";
import { COLOR } from "src/ui/variables";
import { imagePaths } from "src/data/importFromPublic";

type QuizProps = {
  changeSelection: (tickedBoxes: string) => string[] | void;
  checkAnswer?: () => void;
};

type QuizChoice = {
  id: string;
  choice: string;
  picture?: string;
};

const getChoices = <T extends object>(quiz: T) => {
  if (Object.keys(quiz).length < 1) {
    return [];
  }
  const keys = Object.keys(quiz).filter((key) => key.slice(0, 6) === "choice");
  return keys.map((key) => quiz[key as keyof T]);
};

export const LogicWrapperCheckboxes = (props: {
  quiz: IQuiz_Mult | IQuiz_Diff;
  setAnswer: (answer: boolean) => void;
  render: (changeSelection: (val: string) => void) => React.ReactNode;
}) => {
  const { quiz, setAnswer } = props;
  const [tickedBoxes, changeBoxStatus] = useState<string[]>([]);

  useEffect(() => {
    checkAnswer();
  }, [tickedBoxes]);

  const checkAnswer = () => {
    if (!quiz || !quiz.solution) {
      return;
    }
    const containsAll = (checked: string[], solution: string[]) =>
      solution.every((item) => checked.includes(item));

    const answer =
      containsAll(tickedBoxes, quiz.solution) &&
      containsAll(quiz.solution, tickedBoxes);

    setAnswer(answer);
  };

  const changeSelection = (checkboxId: string) => {
    if (tickedBoxes.includes(checkboxId)) {
      const keep = tickedBoxes.filter((checkbox) => checkbox !== checkboxId);
      changeBoxStatus(keep);
    } else {
      changeBoxStatus((checkboxes) => [...checkboxes, checkboxId]);
    }
  };

  return <>{props.render(changeSelection)}</>;
};

export const QuizMultChoice = ({
  changeSelection,
  quiz,
}: QuizProps & { quiz: IQuiz_Mult }) => {
  if (!quiz.station_id) {
    return <div></div>;
  }
  const choices = getChoices(quiz);

  const imagePath = imagePaths.find((path: string) =>
    path.includes(quiz.picture1)
  );
  return (
    <div style={{ padding: "4px 0" }}>
      <QuizQuestion text={quiz.question} />
      {quiz.picture1 ? (
        <img src={imagePath || ""} alt="Quiz Image" className="quizImg" />
      ) : null}
      <IonList>
        {choices &&
          choices.map((choice, index) => (
            <IonItem key={index} lines="none" className="quizItem">
              <IonLabel
                style={{
                  wordBreak: "break-word",
                  paddingRight: 16,
                  textAlign: "justify",
                  color: COLOR.intermediatefont,
                }}
              >
                {choice}
              </IonLabel>
              <IonCheckbox
                slot="start"
                id={`choice${index + 1}`}
                value={choice}
                color="protored"
                onClick={(el) => {
                  changeSelection(el.currentTarget.id);
                }}
              />
            </IonItem>
          ))}
      </IonList>
    </div>
  );
};
export const QuizDifference = ({
  changeSelection,
  quiz,
}: QuizProps & { quiz: IQuiz_Diff }) => {
  if (!quiz.station_id) {
    return <div></div>;
  }
  const options = Object.keys(quiz).reduce((acc: QuizChoice[], cur: string) => {
    if (cur.includes("choice")) {
      const index = cur.slice("choice".length);
      const matchingPicture = Object.keys(quiz).find(
        (key) => key === `picture${index}`
      );

      if (matchingPicture) {
        acc.push({
          id: cur,
          choice: quiz[cur as keyof IQuiz_Diff] as string,
          picture: imagePaths.find((path: string) =>
            path.includes(quiz[matchingPicture as keyof IQuiz_Diff] as string)
          ),
        });
      } else {
        acc.push({
          id: cur,
          choice: quiz[cur as keyof IQuiz_Diff] as string,
        });
      }
      return acc;
    }
    return acc;
  }, []);

  return (
    <div style={{ padding: "20px 0" }}>
      <QuizQuestion text={quiz.question} />
      {options.map((option, index) => (
        <div key={index}>
          {option.picture ? (
            <img src={option.picture} alt="Quiz Image" className="quizImg" />
          ) : null}
          {option.choice ? (
            <IonItem detail={false} lines="none" className="quizItem">
              <IonText
                style={{
                  wordBreak: "break-word",
                  paddingRight: 16,
                  textAlign: "justify",
                  color: COLOR.intermediatefont,
                }}
              >
                {option.choice}
              </IonText>
              <IonCheckbox
                slot="start"
                value={option.choice}
                color="protored"
                id={`choice${index + 1}`}
                onClick={(el) => {
                  changeSelection(el.currentTarget.id);
                }}
              />
            </IonItem>
          ) : null}
        </div>
      ))}
    </div>
  );
};
