import { IonCardContent } from "@ionic/react";
import { GALLERYTYPE, Gallery } from "../uiElements/gallery";
import { IStation } from "../../../data/model";
import { MdBlock } from "../../../data/parser/markdownParser";
import { CustomIonCard } from "../../../ui/other";
import { imagePaths } from "src/data/importFromPublic";

// Position of gallery
export enum GAL_POS {
  TOP = "top",
  BOTTOM = "bottom",
}

const Row = ({ text }: { text: string }) => (
  <p style={{ fontWeight: "bold", textAlign: "left" }}>{text}</p>
);

export const InfoBanner = ({
  data,
  setShowLexiconModal,
}: {
  data: IStation;
  setShowLexiconModal: (state: boolean) => void;
}) => {
  const ownerImg = imagePaths.find(
    (path: string) => path.includes(data.icon.slice(0, -4)) // no file ending
  );

  return (
    <div style={{ flexGrow: 1 }}>
      <hr />
      <img
        style={{
          width: 50,
          height: 50,
          marginRight: 4,
          marginTop: -3,
          float: "right",
        }}
        src={ownerImg}
      />
      <Row text={data.office} />
      <Row text={`${data.year_of_construction.toString()}`} />
      <hr />
    </div>
  );
};

const CardTitel = ({ title }: { title: string }) => (
  <h2 style={{ marginBottom: -6 }}>{title}</h2>
);

export const OverviewCard = ({
  entityId,
  entityName,
  mdPath,
  galleryPosition = GAL_POS.TOP,
  withTitle,
  imagePaths,
  children,
  galleryType,
  audioPlaying,
  setAudioPlaying,
  hasAudioFile,
}: {
  entityId: string;
  entityName: string;
  mdPath: string;
  galleryPosition: GAL_POS;
  imagePaths: string[];
  withTitle: boolean;
  children: React.ReactElement;
  galleryType: GALLERYTYPE;
  audioPlaying: boolean;
  setAudioPlaying: (state: boolean) => void;
  hasAudioFile: boolean;
}) => {
  return (
    <CustomIonCard>
      <IonCardContent>
        <div className="cardWrapper">
          {galleryPosition === GAL_POS.TOP && (
            <Gallery
              id={entityId}
              imagePaths={imagePaths}
              galleryType={galleryType}
              audioPlaying={audioPlaying}
              setAudioPlaying={setAudioPlaying}
              hasAudioFile={hasAudioFile}
            />
          )}
          {withTitle && <CardTitel title={entityName} />}
          {children}
          <MdBlock mdPath={mdPath} />
          {galleryPosition === GAL_POS.BOTTOM && (
            <div style={{ padding: "10px 0 30px 0" }}>
              <Gallery
                id={entityId}
                imagePaths={imagePaths}
                galleryType={galleryType}
                audioPlaying={audioPlaying}
                setAudioPlaying={setAudioPlaying}
                hasAudioFile={hasAudioFile}
              />
            </div>
          )}
        </div>
      </IonCardContent>
    </CustomIonCard>
  );
};
